import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { filter } from 'lodash';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Input from '@material-ui/core/Input';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';

import { Walking2 } from '../lib/icons';
import ProWrapper from '../lib/pro-wrapper';

import Page from './page';

import { updatePRO } from '../state/pro-forms';

const styles = {
  button: {
    margin: 5,
    width: 150,
    height: 40,
    fontSize: 16,
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  checkboxLabel: {
    fontSize: '22px',
  },
  formControl: {
    textAlign: 'center',
  },
  formControlItem: {
    fontSize: '22px',
    textAlign: 'center',
    margin: '1rem',
  },
  titleGroup: {
    margin: 25,
  },
  text: {
    fontSize: '22px',
  },
};

const StyledInput = withStyles(() => ({
  input: {
    textAlign: 'center',
    fontSize: '22px',
  },
}))(Input);

const LargeCheckbox = withStyles(() => ({
  root: {
    transform: 'scale(1.5)',
  },
}))(Checkbox);

class Diabetes8 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      exerciseDaily: null,
      dailyExerciseMinutes: null,
      typesOfExercise: [],
    };

    this.handleContinue = this.handleContinue.bind(this);
  }

  handleContinue() {
    this.props.updatePRO({
      type: 'diabetesInitial',
      position: this.getTrackIndex(),
      value: this.state });
    this.forwardWithQuery(this.props.location.query);
  }

  handleMinutes = (e) => {
    const { value } = e.target;
    this.setState({
      dailyExerciseMinutes: value,
    });
  };

  handleCheckbox = (e) => {
    const { name, checked } = e.target;
    const { typesOfExercise } = this.state;
    if (!checked && typesOfExercise.includes(name)) {
      this.setState({ typesOfExercise: filter(typesOfExercise, (type) => { return type !== name; }) });
    } else if (checked && !typesOfExercise.includes(name)) {
      this.setState({ typesOfExercise: [...typesOfExercise, name] });
    }
  };

  render() {
    const { exerciseDaily, dailyExerciseMinutes, typesOfExercise } = this.state;

    let disabled;

    if (exerciseDaily === true && typesOfExercise.length && dailyExerciseMinutes > 0) {
      disabled = false;
    } else if (exerciseDaily === false) {
      disabled = false;
    } else {
      disabled = true;
    }

    return (
      <ProWrapper
        header="Exercise"
        currentPage={this.getCurrentPage()}
        totalPages={this.getTotalPages()}
        onContinue={this.handleContinue}
        disabled={disabled}
        icon={<Walking2 />}
      >
        <div style={styles.titleGroup}>
          <p style={styles.text}>Do you exercise or perform physical activity daily?</p>
          <div style={styles.buttonGroup}>
            <Button style={styles.button} variant="contained" color={exerciseDaily ? 'primary' : 'default'} onClick={() => this.setState({ exerciseDaily: true })}>Yes</Button>
            <Button style={styles.button} variant="contained" color={exerciseDaily === false ? 'primary' : 'default'} onClick={() => this.setState({ exerciseDaily: false, dailyExerciseMinutes: 0, typesOfExercise: [] })}>No</Button>
          </div>
        </div>

        <div className={`${exerciseDaily ? '' : 'hidden'}`} style={styles.titleGroup}>
          <p style={styles.text}>How many minutes?</p>
          <div style={styles.formControl}>
            <FormControl style={styles.formControlItem}>
              <StyledInput
                id="dailyExerciseMinutes"
                type="tel"
                value={dailyExerciseMinutes}
                name="dailyExerciseMinutes"
                placeholder="000"
                onChange={this.handleMinutes}
                inputProps={{
                  'aria-label': 'dailyExerciseMinutes',
                }}
              />
            </FormControl>
            <Typography>minutes</Typography>
          </div>

          <p style={styles.text}>What type of exercise?</p>
          <FormControl component="fieldset" style={styles.formControlItem}>
            <FormGroup>
              <FormControlLabel
                control={<LargeCheckbox checked={typesOfExercise.includes('Cardio')} onChange={this.handleCheckbox} name="Cardio" />}
                label={(
                  <div style={styles.checkboxLabel}>
                    <Typography variant="title">
                      Cardio
                    </Typography>
                  </div>
                )}
              />
              <FormControlLabel
                control={<LargeCheckbox checked={typesOfExercise.includes('Strength Training')} onChange={this.handleCheckbox} name="Strength Training" />}
                label={(
                  <div style={styles.checkboxLabel}>
                    <Typography variant="title">
                      Strength Training
                    </Typography>
                  </div>
                )}
              />
              <FormControlLabel
                control={<LargeCheckbox checked={typesOfExercise.includes('Yoga/Pilates/Other')} onChange={this.handleCheckbox} name="Yoga/Pilates/Other" />}
                label={(
                  <div style={styles.checkboxLabel}>
                    <Typography variant="title">
                    Yoga/Pilates/Other
                    </Typography>
                  </div>
                )}
              />
            </FormGroup>
          </FormControl>
        </div>
      </ProWrapper>
    );
  }
}

function mapStateToProps(state) {
  const { user, proForms: { diabetesInitial } } = state;
  return { user, diabetesInitial };
}

Diabetes8.proptypes = {
  user: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(Diabetes8);
