import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';

import { Sleeping } from '../lib/icons';
import ProWrapper from '../lib/pro-wrapper';

import Page from './page';

import { updatePRO } from '../state/pro-forms';

const styles = {
  button: {
    margin: 5,
    width: 150,
    height: 40,
    fontSize: 16,
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formControl: {
    textAlign: 'center',
  },
  formControlItem: {
    fontSize: '22px',
    textAlign: 'center',
    margin: '1rem',
  },
  mainContainer: {
    position: 'relative',
    minHeight: '100vh',
    marginBottom: '50px',
  },
  titleGroup: {
    margin: 25,
  },
  text: {
    fontSize: '22px',
  },
  wrapper: {
    paddingBottom: '8rem',
  },
};

const StyledInput = withStyles(() => ({
  input: {
    textAlign: 'center',
    fontSize: '22px',
  },
}))(Input);

class Diabetes11 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      hoursOfSleep: null,
      sleepApnea: null,
      cpap: null,
    };

    this.handleContinue = this.handleContinue.bind(this);
  }

  handleContinue() {
    this.props.updatePRO({
      type: 'diabetesInitial',
      position: this.getTrackIndex(),
      value: this.state });
    this.forwardWithQuery(this.props.location.query);
  }

  handleSleepPerNight = (e) => {
    const { value } = e.target;
    this.setState({
      hoursOfSleep: value,
    });
  };

  render() {
    const { sleepApnea, hoursOfSleep, cpap } = this.state;

    let disabled;

    if (sleepApnea !== null && cpap !== null && hoursOfSleep > 0) {
      disabled = false;
    } else {
      disabled = true;
    }

    return (
      <ProWrapper
        header="Sleep"
        currentPage={this.getCurrentPage()}
        totalPages={this.getTotalPages()}
        onContinue={this.handleContinue}
        disabled={disabled}
        icon={<Sleeping />}
      >
        <div style={styles.titleGroup}>
          <p style={styles.text}>How many hours of sleep are you getting each night?</p>
          <div style={styles.formControl}>
            <FormControl style={styles.formControlItem}>
              <StyledInput
                id="hoursOfSleep"
                type="tel"
                value={hoursOfSleep}
                name="hoursOfSleep"
                placeholder="00"
                onChange={this.handleSleepPerNight}
                inputProps={{
                  'aria-label': 'hoursOfSleep',
                }}
              />
            </FormControl>
            <Typography style={styles.text}>hours</Typography>
          </div>

          <p style={styles.text}>Have you ever been diagnosed with sleep apnea?</p>
          <div style={styles.buttonGroup}>
            <Button style={styles.button} variant="contained" color={sleepApnea ? 'primary' : 'default'} onClick={() => this.setState({ sleepApnea: true })}>Yes</Button>
            <Button style={styles.button} variant="contained" color={sleepApnea === false ? 'primary' : 'default'} onClick={() => this.setState({ sleepApnea: false })}>No</Button>
          </div>

          <p style={styles.text}>Do you wear a CPAP/BIPAP?</p>
          <div style={styles.buttonGroup}>
            <Button style={styles.button} variant="contained" color={cpap ? 'primary' : 'default'} onClick={() => this.setState({ cpap: true })}>Yes</Button>
            <Button style={styles.button} variant="contained" color={cpap === false ? 'primary' : 'default'} onClick={() => this.setState({ cpap: false })}>No</Button>
          </div>
        </div>
      </ProWrapper>
    );
  }
}

function mapStateToProps(state) {
  const { user, proForms: { diabetesInitial } } = state;
  return { user, diabetesInitial };
}

Diabetes11.proptypes = {
  user: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(Diabetes11);
