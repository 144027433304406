import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Button } from '@material-ui/core';

import { Utensils } from '../lib/icons';

import Page from './page';

import { updatePRO } from '../state/pro-forms';
import ProWrapper from '../lib/pro-wrapper';

const styles = {
  button: {
    margin: 5,
    width: 150,
    height: 40,
    fontSize: 16,
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  instructions: {
    fontSize: '1.3rem',
  },
  titleGroup: {
    margin: 25,
  },
  text: {
    fontSize: '22px',
  },
};

class Diabetes7 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      satFatAndCarbIntake: null,
      sugarIntake: null,
    };

    this.handleContinue = this.handleContinue.bind(this);
  }

  handleContinue() {
    this.props.updatePRO({
      type: 'diabetesInitial',
      position: this.getTrackIndex(),
      value: this.state });
    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const { satFatAndCarbIntake, sugarIntake } = this.state;

    return (
      <ProWrapper
        header="Diet"
        currentPage={this.getCurrentPage()}
        totalPages={this.getTotalPages()}
        onContinue={this.handleContinue}
        disabled={satFatAndCarbIntake === null || sugarIntake === null}
        icon={<Utensils />}
      >
        <div style={styles.titleGroup}>
          <p style={styles.text}>Do you watch your saturated fat and carbohydrate intake?</p>
          <div style={styles.buttonGroup}>
            <Button style={styles.button} variant="contained" color={satFatAndCarbIntake ? 'primary' : 'default'} onClick={() => this.setState({ satFatAndCarbIntake: true })}>Yes</Button>
            <Button style={styles.button} variant="contained" color={satFatAndCarbIntake === false ? 'primary' : 'default'} onClick={() => this.setState({ satFatAndCarbIntake: false })}>No</Button>
          </div>
        </div>

        <div style={styles.titleGroup}>
          <p style={styles.text}>Do you watch your sugar intake?</p>
          <div style={styles.buttonGroup}>
            <Button style={styles.button} variant="contained" color={sugarIntake ? 'primary' : 'default'} onClick={() => this.setState({ sugarIntake: true })}>Yes</Button>
            <Button style={styles.button} variant="contained" color={sugarIntake === false ? 'primary' : 'default'} onClick={() => this.setState({ sugarIntake: false })}>No</Button>
          </div>
        </div>
      </ProWrapper>
    );
  }
}


function mapStateToProps(state) {
  const { user, proForms: { diabetesInitial } } = state;
  return { user, diabetesInitial };
}

Diabetes7.proptypes = {
  user: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(Diabetes7);
