import React, { Fragment } from 'react';

import i18nTranslator, { introBodyText, introHeaderText, loggedInSuccessText, loggedOutSuccessText } from './lib/i18next';

import SixMWT1 from './pages/6mwt1';
import SixMWT2 from './pages/6mwt2';
import SixMWT3 from './pages/6mwt3';
import SixMWT4 from './pages/6mwt4';

import Anspe1 from './pages/anspe6-1';
import Anspe2 from './pages/anspe6-2';
import Anspe3 from './pages/anspe6-3';
import Anspe4 from './pages/anspe6-4';

import EqAnxietyDepression from './pages/eq5d3l-anxiety-depression';
import EqHealthState from './pages/eq5d3l-health-state';
import EqMobility from './pages/eq5d3l-mobility';
import EqPainDiscomfort from './pages/eq5d3l-pain-discomfort';
import EqSelfCare from './pages/eq5d3l-self-care';
import EqUsualActivities from './pages/eq5d3l-usual-activities';
import Eq5d3lFunctionality from './pages/eq5d3l-functionality';
import Eq5d3lDisclaimer from './pages/eq5d3l-disclaimer';

import HeadachePRO from './pages/headache-pro';

import Eq5d5lAnxiety from './pages/eq5d5l-anxiety';
import Eq5d5lHealth from './pages/eq5d5l-health';
import Eq5d5lMobility from './pages/eq5d5l-mobility';
import Eq5d5lPain from './pages/eq5d5l-pain';
import Eq5d5lSelfCare from './pages/eq5d5l-self-care';
import Eq5d5lUsualActivities from './pages/eq5d5l-usual-activities';

import MUA1 from './pages/mua-1';
import MUA2 from './pages/mua-2';
import MUA3 from './pages/mua-3';

import MigrainePain from './pages/migraine-pain';

import NdiConcentration from './pages/ndi-concentration';
import NdiDriving from './pages/ndi-driving';
import NdiHeadaches from './pages/ndi-headaches';
import NdiLifting from './pages/ndi-lifting';
import NdiNeckArmPainScale from './pages/ndi-neck-arm-pain-scale';
import NdiPainIntensity from './pages/ndi-pain-intensity';
import NdiPersonalCare from './pages/ndi-personal-care';
import NdiNeckPainScale from './pages/ndi-neck-pain-scale';
import NdiReading from './pages/ndi-reading';
import NdiRecreation from './pages/ndi-recreation';
import NdiSleeping from './pages/ndi-sleeping';
import NdiWork from './pages/ndi-work';

import OdiBackLegPainScale from './pages/odi-back-leg-pain-scale';
import OdiBackPainScale from './pages/odi-back-pain-scale';
import OdiPainIntensity from './pages/odi-pain-intensity';
import OdiPersonalCare from './pages/odi-personal-care';
import OdiLifting from './pages/odi-lifting';
import OdiSitting from './pages/odi-sitting';
import OdiSleeping from './pages/odi-sleeping';
import OdiSocialLife from './pages/odi-social-life';
import OdiStanding from './pages/odi-standing';
import OdiTravel from './pages/odi-travel';
import OdiWalking from './pages/odi-walking';
import OdiEmploymentHomemaking from './pages/odi-employment-homemaking';

import PHQ91 from './pages/phq9-1';
import PHQ92 from './pages/phq9-2';

import Promis29Anxiety from './pages/promis29-anxiety';
import Promis29Depression from './pages/promis29-depression';
import Promis29Fatigue from './pages/promis29-fatigue';
import Promis29PainInterference from './pages/promis29-pain-interference';
import Promis29SleepDisturbance from './pages/promis29-sleep-disturbance';
import Promis29SocialRolesActivities from './pages/promis29-social-roles-activities';
import Promis29PhysicalFunction from './pages/promis29-physical-function';
import Promis29PainIntense from './pages/promis29-horizontal-pain-slider';

import Hit6HeadacheImpactP1 from './pages/hit6-headache-impact-p1';
import Hit6HeadacheImpactP2 from './pages/hit6-headache-impact-p2';

import MidasWork from './pages/midas-work';
import MidasHousework from './pages/midas-household-activities';
import MidasSocial from './pages/midas-social';
import MidasHeadache from './pages/midas-headache';
import BackPain from './pages/pain-pro-back';
import BackPainMovement from './pages/pain-pro-BM';
import PainDetail from './pages/pain-carousel';
import NeckPain from './pages/pain-pro-neck';
import NeckPainMovement from './pages/pain-pro-NM';

import DigestiveHealth1 from './pages/digestive-health-pro-1';
import DigestiveHealth2 from './pages/digestive-health-pro-2';

import StartBack1 from './pages/start-back-pro-1';
import StartBack2 from './pages/start-back-pro-2';
import StartBack3 from './pages/start-back-pro-3';

import WOMAC1 from './pages/womac-1';
import WOMAC2 from './pages/womac-2';
import WOMAC3 from './pages/womac-3';
import WOMAC4 from './pages/womac-4';
import WOMAC5 from './pages/womac-5';
import WOMAC6 from './pages/womac-6';

import PrgEndo1 from './pages/prg-endo-1';
import PrgEndo2 from './pages/prg-endo-2';
import PrgEndo3 from './pages/prg-endo-3';
import PrgEndo4 from './pages/prg-endo-4';
import PrgEndo5 from './pages/prg-endo-5';

import PMJOA1 from './pages/pmjoa-1';
import PMJOA2 from './pages/pmjoa-2';
import PMJOA3 from './pages/pmjoa-3';
import PMJOA4 from './pages/pmjoa-4';

import PISAFollowup1 from './pages/pisa-followup-1';
import PISAFollowup2 from './pages/pisa-followup-2';
import PISAFollowup3 from './pages/pisa-followup-3';
import PISAFollowup4 from './pages/pisa-followup-4';
import PISAFollowup5 from './pages/pisa-followup-5';

import PISAInjection1 from './pages/pisa-injection-1';
import PISAInjection2 from './pages/pisa-injection-2';
import PISAInjection3 from './pages/pisa-injection-3';
import PISAInjection4 from './pages/pisa-injection-4';

import VideoDisclaimer from './pages/video-disclaimer';
import VideoNotification from './pages/video-notification';
import AudioNotification from './pages/audio-notification';

import RothmanDisclaimer from './pages/rothman-disclaimer';

import OsteoporosisRisk1 from './pages/osteoporosis-risk-1';
import OsteoporosisRisk2 from './pages/osteoporosis-risk-2';

import Covid19_1 from './pages/covid19_1';
import Covid19_2 from './pages/covid19_2';
import Covid19_3 from './pages/covid19_3';
import Covid19_4 from './pages/covid19_4';
import Covid19_5 from './pages/covid19_5';
import Covid19_6 from './pages/covid19_6';
import Covid19_7 from './pages/covid19_7';

import SystemsReview1 from './pages/systems-review-1';
import SystemsReview2 from './pages/systems-review-2';

import ReasonForVisitPRO from './pages/reason-for-visit-pro-1';

import AboutMe1 from './pages/about-me-pro-1';

import Medications1 from './pages/medication-pro-1';
import Medications2 from './pages/medication-pro-2';
import Medications3 from './pages/medication-pro-3';
import Medications4 from './pages/medication-pro-4';

import VideoCall1 from './pages/video-call-pro-1';
import VideoCall2 from './pages/video-call-pro-2';

import HeadachePain1 from './pages/headache-pain-pro-1';
import HeadachePain2 from './pages/headache-pain-pro-2';
import HeadachePain3 from './pages/headache-pain-pro-3';
import HeadachePain4 from './pages/headache-pain-pro-4';
import HeadachePain5 from './pages/headache-pain-pro-5';
import HeadachePain6 from './pages/headache-pain-pro-6';

import BodyPain1 from './pages/body-pain-pro-1';
import BodyPain2 from './pages/body-pain-pro-2';
import BodyPain3 from './pages/body-pain-pro-3';
import BodyPain4 from './pages/body-pain-pro-4';
import BodyPain5 from './pages/body-pain-pro-5';
import BodyPain6 from './pages/body-pain-pro-6';
import BodyPain7 from './pages/body-pain-pro-7';
import BodyPain8 from './pages/body-pain-pro-8';
import BodyPain9 from './pages/body-pain-pro-9';

import JohnsHopkinsConsent1 from './pages/johns-hopkins-consent-1';
import JohnsHopkinsConsent2 from './pages/johns-hopkins-consent-2';

import NRS1 from './pages/nrs-1';
import NRS2 from './pages/nrs-2';

import ClinicAccess from './pages/clinic-access';

import VideoVitals1 from './pages/video-vitals-1';
import VideoVitals2 from './pages/video-vitals-2';
import VideoVitals3 from './pages/video-vitals-3';

import VideoVitalsFull1 from './pages/video-vitals-full-1';
import VideoVitalsFull2 from './pages/video-vitals-full-2';
import VideoVitalsFull3 from './pages/video-vitals-full-3';

import AppointmentReminder from './pages/appointment-reminder';
import AppointmentConfirmation1 from './pages/appointment-confirmation-1';
import AppointmentConfirmation2 from './pages/appointment-confirmation-2';
import AppointmentConfirmation3 from './pages/appointment-confirmation-3';
import AppointmentConfirmation4 from './pages/appointment-confirmation-4';

import PainLog1 from './pages/pain-log-1';
import PainLog2 from './pages/pain-log-2';

import ThoracicCancerSymptoms1 from './pages/thoracic-cancer-symptoms-1';
import ThoracicCancerSymptoms2 from './pages/thoracic-cancer-symptoms-2';
import ThoracicCancerSymptoms3 from './pages/thoracic-cancer-symptoms-3';
import ThoracicCancerSymptoms4 from './pages/thoracic-cancer-symptoms-4';
import ThoracicCancerSymptoms5 from './pages/thoracic-cancer-symptoms-5';
import ThoracicCancerSymptoms6 from './pages/thoracic-cancer-symptoms-6';
import ThoracicCancerSymptoms7 from './pages/thoracic-cancer-symptoms-7';
import ThoracicCancerSymptoms8 from './pages/thoracic-cancer-symptoms-8';
import ThoracicCancerSymptoms9 from './pages/thoracic-cancer-symptoms-9';
import ThoracicCancerSymptoms10 from './pages/thoracic-cancer-symptoms-10';

import HeadAndNeckCancerSymptoms1 from './pages/head-and-neck-cancer-symptoms-1';
import HeadAndNeckCancerSymptoms2 from './pages/head-and-neck-cancer-symptoms-2';
import HeadAndNeckCancerSymptoms3 from './pages/head-and-neck-cancer-symptoms-3';
import HeadAndNeckCancerSymptoms4 from './pages/head-and-neck-cancer-symptoms-4';
import HeadAndNeckCancerSymptoms5 from './pages/head-and-neck-cancer-symptoms-5';
import HeadAndNeckCancerSymptoms6 from './pages/head-and-neck-cancer-symptoms-6';
import HeadAndNeckCancerSymptoms7 from './pages/head-and-neck-cancer-symptoms-7';
import HeadAndNeckCancerSymptoms8 from './pages/head-and-neck-cancer-symptoms-8';
import HeadAndNeckCancerSymptoms9 from './pages/head-and-neck-cancer-symptoms-9';
import HeadAndNeckCancerSymptoms10 from './pages/head-and-neck-cancer-symptoms-10';
import HeadAndNeckCancerSymptoms11 from './pages/head-and-neck-cancer-symptoms-11';
import HeadAndNeckCancerSymptoms12 from './pages/head-and-neck-cancer-symptoms-12';
import HeadAndNeckCancerSymptoms13 from './pages/head-and-neck-cancer-symptoms-13';
import HeadAndNeckCancerSymptoms14 from './pages/head-and-neck-cancer-symptoms-14';

import HeadAndNeckCancerSymptomsNoVitals12 from './pages/head-and-neck-cancer-symptoms-no-vitals-12';
import HeadAndNeckCancerSymptomsNoVitals13 from './pages/head-and-neck-cancer-symptoms-no-vitals-13';

import DrJamesonReview1 from './pages/dr-jameson-review-1';
import DrJamesonReview2 from './pages/dr-jameson-review-2';
import DrJamesonReview3 from './pages/dr-jameson-review-3';

import DrTheodoreReview1 from './pages/dr-theodore-review-1';
import DrTheodoreReview2 from './pages/dr-theodore-review-2';
import DrTheodoreReview3 from './pages/dr-theodore-review-3';

import BMI from './pages/bmi-pro-1';

import CaptureInsuranceCard1 from './pages/capture-insurance-card-1';
import CaptureInsuranceCard2 from './pages/capture-insurance-card-2';
import CaptureInsuranceCard3 from './pages/capture-insurance-card-3';

import Wound1 from './pages/wound-pro-1';
import Wound2 from './pages/wound-pro-2';
import Wound3 from './pages/wound-pro-3';

import VitalsDataCollection1 from './pages/vitals-data-collection-pro-1';
import VitalsDataCollection2 from './pages/vitals-data-collection-pro-2';
import VitalsDataCollection3 from './pages/vitals-data-collection-pro-3';
import VitalsDataCollection4 from './pages/vitals-data-collection-pro-4';
import VitalsDataCollection5 from './pages/vitals-data-collection-pro-5';
import VitalsDataCollection6 from './pages/vitals-data-collection-pro-6';
import VitalsDataCollection7 from './pages/vitals-data-collection-pro-7';
import VitalsDataCollection8 from './pages/vitals-data-collection-pro-8';
import VitalsDataCollection9 from './pages/vitals-data-collection-pro-9';
import VitalsDataCollection10 from './pages/vitals-data-collection-pro-10';

import FullDataCollection1 from './pages/full-data-collection-1';
import FullDataCollection2 from './pages/full-data-collection-2';
import FullDataCollection3 from './pages/full-data-collection-3';
import FullDataCollection4 from './pages/full-data-collection-4';
import FullDataCollection5 from './pages/full-data-collection-5';
import FullDataCollection6 from './pages/full-data-collection-6';
import FullDataCollection7 from './pages/full-data-collection-7';
import FullDataCollection8 from './pages/full-data-collection-8';
import FullDataCollection9 from './pages/full-data-collection-9';
import FullDataCollection10 from './pages/full-data-collection-10';
import FullDataCollection11 from './pages/full-data-collection-11';

import ContactSpo2DataCollection1 from './pages/contact-spo2-data-collection-1';
import ContactSpo2DataCollection2 from './pages/contact-spo2-data-collection-2';
// import ContactSpo2DataCollection3 from './pages/contact-spo2-data-collection-3';
import ContactSpo2DataCollection4 from './pages/contact-spo2-data-collection-4';
import ContactSpo2DataCollection5 from './pages/contact-spo2-data-collection-5';
import ContactSpo2DataCollection6 from './pages/contact-spo2-data-collection-6';
import ContactSpo2DataCollection7 from './pages/contact-spo2-data-collection-7';
// import ContactSpo2DataCollection8 from './pages/contact-spo2-data-collection-8';
// import ContactSpo2DataCollection9 from './pages/contact-spo2-data-collection-9';
import ContactSpo2DataCollection10 from './pages/contact-spo2-data-collection-10';
import ContactSpo2DataCollection11 from './pages/contact-spo2-data-collection-11';
import ContactSpo2DataCollection12 from './pages/contact-spo2-data-collection-12';
import ContactSpo2DataCollection13 from './pages/contact-spo2-data-collection-13';

import ContinuousBPDataCollection1 from './pages/continuous-bp-data-collection-1';
import ContinuousBPDataCollectionInstructions from './pages/continuous-bp-data-collection-instructions';
import ContinuousBPDataCollectionManualBp from './pages/continuous-bp-data-collection-manualbp';
import ContinuousBPDataCollectionCameraMode from './pages/continuous-bp-data-collection-camera-mode';
import continuousBpDataCollectionCalibrationMode from './pages/continuous-bp-data-collection-calibration-mode';
import ContinuousBPDataCollection2 from './pages/continuous-bp-data-collection-2';
import ContinuousBPDataCollection3 from './pages/continuous-bp-data-collection-3';
import ContinuousBPDataCollection4 from './pages/continuous-bp-data-collection-4';
import ContinuousBPDataCollection5 from './pages/continuous-bp-data-collection-5';
import ContinuousBPDataCollection6 from './pages/continuous-bp-data-collection-6';
import ContinuousBPDataCollection7 from './pages/continuous-bp-data-collection-7';
import ContinuousBPDataCollection8 from './pages/continuous-bp-data-collection-8';
import ContinuousBPDataCollection9 from './pages/continuous-bp-data-collection-9';
import ContinuousBPDataCollection10 from './pages/continuous-bp-data-collection-10';
import ContinuousBPDataCollection11 from './pages/continuous-bp-data-collection-11';

import DiabetesDaily1 from './pages/diabetes-daily-pro-1';
import DiabetesDaily2 from './pages/diabetes-daily-pro-2';
import DiabetesDaily3 from './pages/diabetes-daily-pro-3';
import DiabetesDaily4 from './pages/diabetes-daily-pro-4';
import DiabetesDaily5 from './pages/diabetes-daily-pro-5';
import DiabetesDaily6 from './pages/diabetes-daily-pro-6';
import DiabetesDaily7 from './pages/diabetes-daily-pro-7';

import DiabetesInitial1 from './pages/diabetes-initial-pro-1';
import DiabetesInitial2 from './pages/diabetes-initial-pro-2';
import DiabetesInitial3 from './pages/diabetes-initial-pro-3';
import DiabetesInitial4 from './pages/diabetes-initial-pro-4';
import DiabetesInitial5 from './pages/diabetes-initial-pro-5';
import DiabetesInitial6 from './pages/diabetes-initial-pro-6';
import DiabetesInitial7 from './pages/diabetes-initial-pro-7';
import DiabetesInitial8 from './pages/diabetes-initial-pro-8';
import DiabetesInitial9 from './pages/diabetes-initial-pro-9';
import DiabetesInitial10 from './pages/diabetes-initial-pro-10';
import DiabetesInitial11 from './pages/diabetes-initial-pro-11';
import DiabetesInitial12 from './pages/diabetes-initial-pro-12';
import DiabetesInitial13 from './pages/diabetes-initial-pro-13';
import DiabetesInitial14 from './pages/diabetes-initial-pro-14';
import DiabetesInitial15 from './pages/diabetes-initial-pro-15';
import DiabetesInitial16 from './pages/diabetes-initial-pro-16';

import HypertensionAssessmentInitial1 from './pages/hypertension-assessment-initial-1';
import HypertensionAssessmentInitial2 from './pages/hypertension-assessment-initial-2';
import HypertensionAssessmentInitial3 from './pages/hypertension-assessment-initial-3';
import HypertensionAssessmentInitial4 from './pages/hypertension-assessment-initial-4';
import HypertensionAssessmentInitial5 from './pages/hypertension-assessment-initial-5';
import HypertensionAssessmentInitial6 from './pages/hypertension-assessment-initial-6';
import HypertensionAssessmentInitial7 from './pages/hypertension-assessment-initial-7';
import HypertensionAssessmentInitial8 from './pages/hypertension-assessment-initial-8';
import HypertensionAssessmentInitial9 from './pages/hypertension-assessment-initial-9';
import HypertensionAssessmentInitial10 from './pages/hypertension-assessment-initial-10';
import HypertensionAssessmentInitial11 from './pages/hypertension-assessment-initial-11';
import HypertensionAssessmentInitial12 from './pages/hypertension-assessment-initial-12';
import HypertensionAssessmentInitial13 from './pages/hypertension-assessment-initial-13';
import HypertensionAssessmentInitial14 from './pages/hypertension-assessment-initial-14';

import HypertensionAssessmentDaily1 from './pages/hypertension-assessment-daily-1';
import HypertensionAssessmentDaily2 from './pages/hypertension-assessment-daily-2';
import HypertensionAssessmentDaily3 from './pages/hypertension-assessment-daily-3';
import HypertensionAssessmentDaily4 from './pages/hypertension-assessment-daily-4';
import HypertensionAssessmentDaily5 from './pages/hypertension-assessment-daily-5';
import HypertensionAssessmentDaily6 from './pages/hypertension-assessment-daily-6';
import HypertensionAssessmentDaily7 from './pages/hypertension-assessment-daily-7';
import HypertensionAssessmentDaily8 from './pages/hypertension-assessment-daily-8';

import HypertensionAssessmentDailyNoVitals8 from './pages/hypertension-assessment-daily-no-vitals-8';

import HeartFailureInitial1 from './pages/heart-failure-initial-1';
import HeartFailureInitial2 from './pages/heart-failure-initial-2';
import HeartFailureInitial3 from './pages/heart-failure-initial-3';
import HeartFailureInitial4 from './pages/heart-failure-initial-4';
import HeartFailureInitial5 from './pages/heart-failure-initial-5';
import HeartFailureInitial6 from './pages/heart-failure-initial-6';
import HeartFailureInitial7 from './pages/heart-failure-initial-7';
import HeartFailureInitial8 from './pages/heart-failure-initial-8';
import HeartFailureInitial9 from './pages/heart-failure-initial-9';
import HeartFailureInitial10 from './pages/heart-failure-initial-10';
import HeartFailureInitial11 from './pages/heart-failure-initial-11';
import HeartFailureInitial12 from './pages/heart-failure-initial-12';
import HeartFailureInitial13 from './pages/heart-failure-initial-13';
import HeartFailureInitial14 from './pages/heart-failure-initial-14';
import HeartFailureInitial15 from './pages/heart-failure-initial-15';

import HeartFailureDaily1 from './pages/heart-failure-daily-1';
import HeartFailureDaily2 from './pages/heart-failure-daily-2';
import HeartFailureDaily3 from './pages/heart-failure-daily-3';
import HeartFailureDaily4 from './pages/heart-failure-daily-4';
import HeartFailureDaily5 from './pages/heart-failure-daily-5';
import HeartFailureDaily6 from './pages/heart-failure-daily-6';

import VitalCore1 from './pages/vital-core-1';
import VitalCore2 from './pages/vital-core-2';
import VitalCore3 from './pages/vital-core-3';
import VitalCore4 from './pages/vital-core-4';
import VitalCore5 from './pages/vital-core-5';
import VitalCore6 from './pages/vital-core-6';
import VitalCore7 from './pages/vital-core-7';
import VitalCore8 from './pages/vital-core-8';
import VitalCore9 from './pages/vital-core-9';
import VitalCore4VitalsResults from './pages/vital-core-4-vitals-results';

import VitalCoreRR1 from './pages/vital-core-rr-1';
import VitalCoreRR2 from './pages/vital-core-rr-2';
import VitalCoreRR3 from './pages/vital-core-rr-3';
import VitalCoreRR4 from './pages/vital-core-rr-4';
import VitalCoreRR5 from './pages/vital-core-rr-5';
import VitalCoreRR6 from './pages/vital-core-rr-6';
import VitalCoreRR7 from './pages/vital-core-rr-7';
import VitalCoreRR8 from './pages/vital-core-rr-8';
import VitalCoreRR9 from './pages/vital-core-rr-9';

import IvcRRClinicalStudy1 from './pages/ivc-rr-clinical-study-1';
import IvcRRClinicalStudy2 from './pages/ivc-rr-clinical-study-2';
import IvcRRClinicalStudy3 from './pages/ivc-rr-clinical-study-3';

import VitalCoreBenchHrInfo from './pages/vital-core-bench-hr-info';
import VitalCoreBenchHrBmi from './pages/vital-core-bench-hr-bmi';
import VitalCoreBenchHrMakeup from './pages/vital-core-bench-hr-makeup';
import VitalCoreBenchHrFitzpatrick from './pages/vital-core-bench-hr-fitzpatrick';
import VitalCoreBenchHrFacial from './pages/vital-core-bench-hr-facial';
import VitalCoreBenchHrPair from './pages/vital-core-bench-hr-pair';
import VitalCoreBenchHrPreview from './pages/vital-core-bench-hr-preview';
import VitalCoreBenchHr5 from './pages/vital-core-bench-hr-5';
import VitalCoreBenchHr6 from './pages/vital-core-bench-hr-6';
import VitalCoreBenchHr7 from './pages/vital-core-bench-hr-7';
import VitalCoreBenchHr8 from './pages/vital-core-bench-hr-8';
import VitalCoreBenchHr9 from './pages/vital-core-bench-hr-9';

import EducationAssessment1 from './pages/education-assessment-1';
import EducationAssessment2 from './pages/education-assessment-2';

import PatientDemographics1 from './pages/patient-demographics-1';
import PatientDemographics2 from './pages/patient-demographics-2';
import PatientDemographics3 from './pages/patient-demographics-3';
import PatientDemographics4 from './pages/patient-demographics-4';
import PatientDemographics5 from './pages/patient-demographics-5';
import PatientDemographics6 from './pages/patient-demographics-6';
import PatientDemographics7 from './pages/patient-demographics-7';
import PatientDemographics8 from './pages/patient-demographics-8';
import PatientDemographics9 from './pages/patient-demographics-9';

import WoundCasaColina1 from './pages/wound-casa-colina-1';
// import WoundCasaColina2 from './pages/wound-casa-colina-2';
import WoundCasaColina3 from './pages/wound-casa-colina-3';
import WoundCasaColina4 from './pages/wound-casa-colina-4';
import WoundCasaColina5 from './pages/wound-casa-colina-5';

import HumanFactorsForIvcApp1 from './pages/human-factors-for-ivc-app-1';
import HumanFactorsForIvcApp2 from './pages/human-factors-for-ivc-app-2';
import HumanFactorsForIvcApp3 from './pages/human-factors-for-ivc-app-3';
import HumanFactorsForIvcApp4 from './pages/human-factors-for-ivc-app-4';

import GIOncology1 from './pages/gi-oncology-1';
import GIOncology2 from './pages/gi-oncology-2';
import GIOncology3 from './pages/gi-oncology-3';
import GIOncology4 from './pages/gi-oncology-4';
import GIOncology5 from './pages/gi-oncology-5';
import GIOncology6 from './pages/gi-oncology-6';

import RespiratoryOncology1 from './pages/respiratory-oncology-1';
import RespiratoryOncology2 from './pages/respiratory-oncology-2';
import RespiratoryOncology3 from './pages/respiratory-oncology-3';
import RespiratoryOncology4 from './pages/respiratory-oncology-4';

import Neutropenia1 from './pages/neutropenia-1';
import Neutropenia2 from './pages/neutropenia-2';

import GAD71 from './pages/gad7-1';
import GAD72 from './pages/gad7-2';

import { bloomkidzStyles } from './lib/bloomkidz';

import BloomkidzNewClient1 from './pages/bloomkidz-new-client-1';
import BloomkidzNewClient2 from './pages/bloomkidz-new-client-2';
import BloomkidzNewClient3 from './pages/bloomkidz-new-client-3';
import BloomkidzNewClient4 from './pages/bloomkidz-new-client-4';
import BloomkidzNewClient5 from './pages/bloomkidz-new-client-5';
import BloomkidzNewClient6 from './pages/bloomkidz-new-client-6';
import BloomkidzNewClient7 from './pages/bloomkidz-new-client-7';
import BloomkidzNewClient8 from './pages/bloomkidz-new-client-8';

import BloomkidzMealAndNutrition1 from './pages/bloomkidz-meal-and-nutrition-1';
import BloomkidzMealAndNutrition2 from './pages/bloomkidz-meal-and-nutrition-2';
import BloomkidzMealAndNutrition3 from './pages/bloomkidz-meal-and-nutrition-3';
import BloomkidzMealAndNutrition4 from './pages/bloomkidz-meal-and-nutrition-4';

import BloomkidzClientWellnessProfile1 from './pages/bloomkidz-client-wellness-profile-1';
import BloomkidzClientWellnessProfile2 from './pages/bloomkidz-client-wellness-profile-2';
import BloomkidzClientWellnessProfile3 from './pages/bloomkidz-client-wellness-profile-3';
import BloomkidzClientWellnessProfile4 from './pages/bloomkidz-client-wellness-profile-4';
import BloomkidzClientWellnessProfile5 from './pages/bloomkidz-client-wellness-profile-5';
import BloomkidzClientWellnessProfile6 from './pages/bloomkidz-client-wellness-profile-6';

import BloomkidzMedicalHistory1 from './pages/bloomkidz-medical-history-1';
import BloomkidzMedicalHistory2 from './pages/bloomkidz-medical-history-2';
import BloomkidzMedicalHistory3 from './pages/bloomkidz-medical-history-3';
import BloomkidzMedicalHistory4 from './pages/bloomkidz-medical-history-4';
import BloomkidzMedicalHistory5 from './pages/bloomkidz-medical-history-5';
import BloomkidzMedicalHistory6 from './pages/bloomkidz-medical-history-6';
import BloomkidzMedicalHistory7 from './pages/bloomkidz-medical-history-7';
import BloomkidzMedicalHistory8 from './pages/bloomkidz-medical-history-8';
import BloomkidzMedicalHistory9 from './pages/bloomkidz-medical-history-9';
import BloomkidzMedicalHistory10 from './pages/bloomkidz-medical-history-10';
import BloomkidzMedicalHistory11 from './pages/bloomkidz-medical-history-11';
import BloomkidzMedicalHistory12 from './pages/bloomkidz-medical-history-12';
import BloomkidzMedicalHistory13 from './pages/bloomkidz-medical-history-13';

import BloomkidzSignatures1 from './pages/bloomkidz-signatures-1';
import BloomkidzSignatures2 from './pages/bloomkidz-signatures-2';
import BloomkidzSignatures3 from './pages/bloomkidz-signatures-3';
import BloomkidzSignatures4 from './pages/bloomkidz-signatures-4';
import BloomkidzSignatures5 from './pages/bloomkidz-signatures-5';
import BloomkidzSignatures6 from './pages/bloomkidz-signatures-6';
import BloomkidzSignatures7 from './pages/bloomkidz-signatures-7';
import BloomkidzSignatures8 from './pages/bloomkidz-signatures-8';
import BloomkidzSignatures9 from './pages/bloomkidz-signatures-9';
import BloomkidzSignatures10 from './pages/bloomkidz-signatures-10';
import BloomkidzSignatures11 from './pages/bloomkidz-signatures-11';
import BloomkidzSignatures12 from './pages/bloomkidz-signatures-12';
import BloomkidzSignatures13 from './pages/bloomkidz-signatures-13';
import BloomkidzSignatures14 from './pages/bloomkidz-signatures-14';
import BloomkidzSignatures15 from './pages/bloomkidz-signatures-15';
import BloomkidzSignatures16 from './pages/bloomkidz-signatures-16';
import BloomkidzSignatures17 from './pages/bloomkidz-signatures-17';
import BloomkidzSignatures18 from './pages/bloomkidz-signatures-18';
import BloomkidzSignatures19 from './pages/bloomkidz-signatures-19';
import BloomkidzSignatures20 from './pages/bloomkidz-signatures-20';
import BloomkidzSignatures21 from './pages/bloomkidz-signatures-21';

import bloomkidzParentGuardianInformation1 from './pages/bloomkidz-parent-guardian-information-1';
import bloomkidzParentGuardianInformation2 from './pages/bloomkidz-parent-guardian-information-2';
import bloomkidzParentGuardianInformation3 from './pages/bloomkidz-parent-guardian-information-3';
import bloomkidzParentGuardianInformation4 from './pages/bloomkidz-parent-guardian-information-4';
import bloomkidzParentGuardianInformation5 from './pages/bloomkidz-parent-guardian-information-5';
import bloomkidzParentGuardianInformation6 from './pages/bloomkidz-parent-guardian-information-6';
import bloomkidzParentGuardianInformation7 from './pages/bloomkidz-parent-guardian-information-7';
import bloomkidzParentGuardianInformation8 from './pages/bloomkidz-parent-guardian-information-8';
import bloomkidzParentGuardianInformation9 from './pages/bloomkidz-parent-guardian-information-9';
import bloomkidzParentGuardianInformation10 from './pages/bloomkidz-parent-guardian-information-10';

const notificationTracks = [
  {
    type: '6-MINUTE-WALK-TEST',
    introHeaderText: 'Patient Reported Outcomes',
    introBodyText: 'requests that you complete the 6 Minute Walk Test',
    loggedOutSuccessText: 'Thank you for submitting your 6 Minute Walk Test. This information is important to your care. You may now close this window.',
    loggedInSuccessText: 'Successful 6 Minute Walk Test!',
    components: [SixMWT1, SixMWT2, SixMWT3, SixMWT4],
  },
  {
    type: 'ANSPE-6',
    introHeaderText: 'Patient Reported Outcomes',
    introBodyText: 'requests that you complete the ANSPE-6',
    loggedOutSuccessText: 'Thank you for submitting your ANSPE-6 Assessment. This information is important to your care. You may now close this window.',
    loggedInSuccessText: 'Successful ANSPE-6 Report!',
    components: [Anspe1, Anspe2, Anspe3, Anspe4],
  },
  {
    type: 'COVID-19',
    introHeaderText: 'Patient Reported Assessment',
    introBodyText: 'requests that you complete a COVID-19 assessment.',
    loggedOutSuccessText: 'Thank you for submitting your COVID-19 assessment. This information is important to your care. You may now close this window.',
    loggedInSuccessText: 'Successful COVID-19 assessment!',
    components: [Covid19_1, Covid19_2, Covid19_3, Covid19_4, Covid19_5, Covid19_6, Covid19_7],
  },
  {
    type: 'EQ5D-3L',
    introHeaderText: introHeaderText('eq5D3LNotificationPage'),
    introBodyText: introBodyText('eq5D3LNotificationPage'),
    loggedOutSuccessText: loggedOutSuccessText('eq5D3LNotificationPage'),
    loggedInSuccessText: loggedInSuccessText('eq5D3LNotificationPage'),
    components: [EqMobility, EqSelfCare, EqUsualActivities, EqPainDiscomfort, EqAnxietyDepression, EqHealthState],
  },
  {
    type: 'EQ5D-3L-CASA-COLINA',
    introHeaderText: introHeaderText('eq5D3LNotificationPage'),
    introBodyText: introBodyText('eq5D3LNotificationPage'),
    loggedOutSuccessText: loggedOutSuccessText('eq5D3LNotificationPage'),
    loggedInSuccessText: loggedInSuccessText('eq5D3LNotificationPage'),
    components: [Eq5d3lDisclaimer, EqMobility, EqSelfCare, EqUsualActivities, EqPainDiscomfort, EqAnxietyDepression, EqHealthState, Eq5d3lFunctionality],
  },
  {
    type: 'EQ5D-5L',
    introHeaderText: 'Patient Reported Outcomes',
    introBodyText: 'requests that you complete the EQ5D-5L',
    loggedOutSuccessText: (<Fragment><div>Thank you for submitting your EQ5D-5L Assessment. This information is important to your care. You may now close this window.</div><div style={{ fontSize: '1.2rem', marginTop: '50px' }}>EQ-5D™ is a trade mark of the EuroQol Research Foundation</div></Fragment>),
    loggedInSuccessText: (<Fragment><div>Successful EQ5D-5L Report!</div><div style={{ fontSize: '1.2rem', marginTop: '50px' }}>EQ-5D™ is a trade mark of the EuroQol Research Foundation</div></Fragment>),
    components: [
      Eq5d5lMobility,
      Eq5d5lSelfCare,
      Eq5d5lUsualActivities,
      Eq5d5lPain,
      Eq5d5lAnxiety,
      Eq5d5lHealth,
    ],
  },
  {
    type: 'NDI',
    introHeaderText: 'Patient Reported Outcomes',
    introBodyText: 'requests that you complete the NDI',
    loggedOutSuccessText: 'Thank you for submitting your NDI Assessment. This information is important to your care. You may now close this window.',
    loggedInSuccessText: 'Successful NDI Report!',
    components: [
      NdiNeckPainScale,
      NdiNeckArmPainScale,
      NdiPainIntensity,
      NdiPersonalCare,
      NdiLifting,
      NdiReading,
      NdiHeadaches,
      NdiConcentration,
      NdiWork,
      NdiDriving,
      NdiSleeping,
      NdiRecreation,
    ],
  },
  {
    type: 'ODI',
    introHeaderText: 'Patient Reported Outcomes',
    introBodyText: 'requests that you complete the ODI',
    loggedOutSuccessText: 'Thank you for submitting your ODI Assessment. This information is important to your care. You may now close this window.',
    loggedInSuccessText: 'Successful ODI Report!',
    components: [
      OdiBackPainScale,
      OdiBackLegPainScale,
      OdiPainIntensity,
      OdiPersonalCare,
      OdiLifting,
      OdiWalking,
      OdiSitting,
      OdiStanding,
      OdiSleeping,
      OdiSocialLife,
      OdiTravel,
      OdiEmploymentHomemaking,
    ],
  },
  {
    type: 'PISA-FOLLOWUP',
    introHeaderText: 'Patient Reported Outcomes',
    introBodyText: 'requests that you complete a PISA-FOLLOWUP assessment',
    loggedOutSuccessText: 'Thank you for submitting your PISA-FOLLOWUP. This information is important to your care. You may now close this window.',
    loggedInSuccessText: 'Successful PISA-FOLLOWUP Report!',
    components: [
      PISAFollowup1,
      PISAFollowup2,
      PISAFollowup3,
      PISAFollowup4,
      PISAFollowup5,
    ],
  },
  {
    type: 'PISA-RFA',
    introHeaderText: 'Patient Reported Outcomes',
    introBodyText: 'requests that you complete a PISA-RFA assessment',
    loggedOutSuccessText: 'Thank you for submitting your PISA-RFA. This information is important to your care. You may now close this window.',
    loggedInSuccessText: 'Successful PISA-RFA Report!',
    components: [
      PISAFollowup1,
      PISAFollowup2,
      PISAFollowup3,
      PISAFollowup4,
      PISAFollowup5,
    ],
  },
  {
    type: 'PISA-INJECTION',
    introHeaderText: introHeaderText('pisaInjectionNotificationPage'),
    introBodyText: introBodyText('pisaInjectionNotificationPage'),
    loggedInSuccessText: loggedInSuccessText('pisaInjectionNotificationPage'),
    loggedOutSuccessText: loggedOutSuccessText('pisaInjectionNotificationPage'),
    components: [
      PISAInjection1,
      PISAInjection2,
      PISAInjection3,
      PISAInjection4,
    ],
  },
  {
    type: 'PHQ9',
    introHeaderText: 'Patient Reported Outcomes',
    introBodyText: 'requests that you complete a Patient Health Questionnaire',
    loggedInSuccessText: 'Successful PHQ9 Report!',
    loggedOutSuccessText: 'Thank you for submitting your PHQ9 Assessment. This information is important to your care. You may now close this window.',
    components: [PHQ91, PHQ92],
  },
  {
    type: 'PROMIS-29',
    introHeaderText: 'Patient Reported Outcomes',
    introBodyText: 'requests that you complete the Promis-29',
    loggedInSuccessText: 'Successful Promis29 Report!',
    loggedOutSuccessText: 'Thank you for submitting your Promis29 Report. This information is important to your care. You may now close this window.',
    components: [
      Promis29Anxiety,
      Promis29Depression,
      Promis29Fatigue,
      Promis29PainInterference,
      Promis29SleepDisturbance,
      Promis29SocialRolesActivities,
      Promis29PhysicalFunction,
      Promis29PainIntense,
    ],
  },
  {
    type: 'PRG-ENDO',
    introHeaderText: 'Patient Reported Outcomes',
    introBodyText: 'requests that you complete the PRG-Endo',
    loggedInSuccessText: 'Thank you for taking this endometriosis screening questionnaire. Your feedback is important to us.',
    loggedOutSuccessText: 'Thank you for taking this endometriosis screening questionnaire. Your feedback is important to us. You may now close this window.',
    components: [
      PrgEndo1,
      PrgEndo2,
      PrgEndo3,
      PrgEndo4,
      PrgEndo5,
    ],
  },
  {
    type: 'HIT-6',
    introHeaderText: 'Patient Reported Outcomes',
    introBodyText: 'requests that you complete the HIT6',
    loggedInSuccessText: 'Successful HIT6 Report!',
    loggedOutSuccessText: 'Thank you for submitting your HIT6 Assessment. This information is important to your care. You may now close this window.',
    components: [Hit6HeadacheImpactP1, Hit6HeadacheImpactP2],
  },
  {
    type: 'MIDAS',
    introHeaderText: 'Patient Reported Outcomes',
    introBodyText: 'requests that you complete the Midas',
    loggedInSuccessText: 'Successful Midas Report!',
    loggedOutSuccessText: 'Thank you for submitting your Midas Assessment. This information is important to your care. You may now close this window!',
    components: [MidasWork, MidasHousework, MidasSocial, MidasHeadache],
  },
  {
    type: 'MUA',
    introHeaderText: 'Patient Reported Outcomes',
    introBodyText: 'requests that you complete the MUA',
    loggedInSuccessText: 'Successful MUA Report!',
    loggedOutSuccessText: 'Thank you for submitting your MUA Assessment. This information is important to your care. You may now close this window.',
    components: [MUA1, MUA2, MUA3],
  },
  {
    type: 'HEADACHE',
    introHeaderText: 'Patient Reported Outcomes',
    introBodyText: 'requests that you complete a headache assessment',
    loggedInSuccessText: 'Successful Headache Report!',
    loggedOutSuccessText: 'Thank you for submitting your Headache Assessment. This information is important to your care. You may now close this window.',
    components: [HeadachePRO, MigrainePain],
  },
  {
    type: 'HEADACHE-PAIN',
    introHeaderText: 'Patient Reported Outcomes',
    introBodyText: 'requests that you complete a headache assessment',
    loggedInSuccessText: 'Successful Headache Report!',
    loggedOutSuccessText: 'Thank you for submitting your Headache Assessment. This information is important to your care. You may now close this window.',
    components: [HeadachePain1, HeadachePain2, HeadachePain3, HeadachePain4, HeadachePain5, HeadachePain6],
  },
  {
    type: 'PAIN',
    introHeaderText: 'Patient Reported Outcomes',
    introBodyText: 'requests that you complete a pain assessment',
    loggedInSuccessText: 'Successful Pain Report!',
    loggedOutSuccessText: 'Thank you for submitting your Pain Assessment. This information is important to your care. You may now close this window.',
    components: [BackPain, BackPainMovement, PainDetail, NeckPain, NeckPainMovement, PainDetail],
  },
  {
    type: 'BODY-PAIN',
    introHeaderText: 'Patient Reported Outcomes',
    introBodyText: 'requests that you complete a pain assessment',
    loggedInSuccessText: 'Successful Body Pain Report!',
    loggedOutSuccessText: 'Thank you for submitting your Body Pain Assessment. This information is important to your care. You may now close this window.',
    components: [BodyPain1, BodyPain2, BodyPain3, BodyPain4, BodyPain5, BodyPain6, BodyPain7, BodyPain8, BodyPain9],
  },
  {
    type: 'START-BACK',
    introHeaderText: 'Patient Reported Outcomes',
    introBodyText: 'requests that you complete a STarT Back assessment',
    loggedInSuccessText: 'Successful STarT Back Report!',
    loggedOutSuccessText: 'Thank you for submitting your STarT Assessment. This information is important to your care. You may now close this window.',
    components: [StartBack1, StartBack2, StartBack3],
  },
  {
    type: 'DIGESTIVE-HEALTH',
    introHeaderText: 'Patient Reported Outcomes',
    introBodyText: 'requests that you complete a digestive health assessment',
    loggedInSuccessText: 'Successful Digestive Health Report!',
    loggedOutSuccessText: 'Thank you for submitting your Digestive Health Assessment. This information is important to your care. You may now close this window.',
    components: [DigestiveHealth1, DigestiveHealth2],
  },
  {
    type: 'VIDEO_BROADCAST',
    introHeaderText: introHeaderText('videoBroadcastNotificationPage'),
    introBodyText: introBodyText('videoBroadcastNotificationPage'),
    loggedInSuccessText: loggedInSuccessText('videoBroadcastNotificationPage'),
    loggedOutSuccessText: loggedOutSuccessText('videoBroadcastNotificationPage'),
    components: [VideoDisclaimer, VideoNotification],
  },
  {
    type: 'VOICE_BROADCAST',
    introHeaderText: 'Patient Information',
    introBodyText: 'requests that you listen to an important message',
    loggedInSuccessText: '',
    loggedOutSuccessText: 'You may now close this window.',
    components: [AudioNotification],
  },
  {
    type: 'WOMAC-HIPS',
    introHeaderText: 'Patient Reported Outcomes',
    introBodyText: 'requests that you complete a WOMAC-HIPS assessment',
    loggedInSuccessText: (<Fragment><div>Successful WOMAC-HIPS Report!</div><div style={{ fontSize: '1.2rem', marginTop: '50px' }}> WOMAC® is a registered trademark</div></Fragment>),
    loggedOutSuccessText: (<Fragment><div>Successful WOMAC-HIPS Report!</div><div style={{ fontSize: '1.2rem', marginTop: '50px' }}> WOMAC® is a registered trademark</div></Fragment>),
    components: [
      WOMAC1,
      WOMAC2,
      WOMAC3,
      WOMAC4,
      WOMAC5,
      WOMAC6,
    ],
    additionalComponentProps: { type: 'hips' },
  },
  {
    type: 'WOMAC-KNEES',
    introHeaderText: 'Patient Reported Outcomes',
    introBodyText: 'requests that you complete a WOMAC-KNEES assessment',
    loggedInSuccessText: (<Fragment><div>Successful WOMAC-KNEES Report!</div><div style={{ fontSize: '1.2rem', marginTop: '50px' }}> WOMAC® is a registered trademark</div></Fragment>),
    loggedOutSuccessText: (<Fragment><div>Successful WOMAC-KNEES Report!</div><div style={{ fontSize: '1.2rem', marginTop: '50px' }}> WOMAC® is a registered trademark</div></Fragment>),
    components: [
      WOMAC1,
      WOMAC2,
      WOMAC3,
      WOMAC4,
      WOMAC5,
      WOMAC6,
    ],
    additionalComponentProps: { type: 'knees' },
  },
  {
    type: 'PMJOA',
    introHeaderText: 'Patient Reported Outcomes',
    introBodyText: 'requests that you complete the PMJOA',
    loggedInSuccessText: 'Successful PmJOA Report!',
    loggedOutSuccessText: 'Thank you for submitting your PMJOA Assessment. This information is important to your care. You may now close this window.',
    components: [PMJOA1, PMJOA2, PMJOA3, PMJOA4],
  },
  {
    type: 'BUNDLED_EVENT',
    introHeaderText: introHeaderText('bundledEventNotifications'),
    introBodyText: introBodyText('bundledEventNotifications'),
    loggedInSuccessText: loggedInSuccessText('bundledEventNotifications'),
    loggedOutSuccessText: loggedOutSuccessText('bundledEventNotifications'),
    components: [], // This one has some special handling as it just combined the above but the empty array is still needed
  },
  {
    type: 'ROTHMAN-DISCLAIMER',
    introHeaderText: 'Disclaimer',
    introBodyText: 'requests that you review a disclaimer',
    loggedInSuccessText: 'Thank you for submitting your responses.',
    loggedOutSuccessText: 'Thank you for submitting your responses. You may now close this window.',
    components: [RothmanDisclaimer],
  },
  {
    type: 'JOHNS-HOPKINS-CONSENT',
    introHeaderText: 'Patient Informed Consent and Privacy Authorization',
    introBodyText: 'requests that you review a form',
    loggedInSuccessText: 'Thank you for submitting your responses.',
    loggedOutSuccessText: 'Thank you for submitting your responses. You may now close this window.',
    components: [JohnsHopkinsConsent1, JohnsHopkinsConsent2],
  },
  {
    type: 'OSTEOPOROSIS-RISK',
    introHeaderText: 'Patient Reported Outcomes',
    introBodyText: 'requests that you complete an osteoporosis risk assessment',
    loggedInSuccessText: (<Fragment><div>Successful Osteoporosis Risk Report!</div><div style={{ fontSize: '1.2rem', marginTop: '50px' }}> Copyright: MedCalc 3000 is Copyright © 1998-2011 Foundation Internet Services, LLC</div></Fragment>),
    loggedOutSuccessText: (<Fragment><div>Thank you for submitting your responses. You may now close this window.</div><div style={{ fontSize: '1.2rem', marginTop: '50px' }}> Copyright: MedCalc 3000 is Copyright © 1998-2011</div><div style={{ fontSize: '1.2rem', marginTop: '5px' }}> Foundation Internet Services, LLC</div></Fragment>),
    components: [OsteoporosisRisk1, OsteoporosisRisk2],
  },
  {
    type: 'REASON-FOR-VISIT',
    introHeaderText: 'Patient Reported Outcomes',
    introBodyText: 'requests that you provide the reason for your upcoming appointment',
    loggedInSuccessText: 'Thank you for submitting your response.',
    loggedOutSuccessText: 'Thank you for submitting your response. You may now close this window.',
    components: [ReasonForVisitPRO],
  },
  {
    type: 'SYSTEMS-REVIEW',
    introHeaderText: 'Patient Reported Outcomes',
    introBodyText: 'requests that you ensure your information is up to date',
    loggedInSuccessText: 'Thank you for submitting your responses.',
    loggedOutSuccessText: 'Thank you for submitting your responses. You may now close this window.',
    components: [SystemsReview1, SystemsReview2],
  },
  {
    type: 'ABOUT-ME',
    introHeaderText: 'Patient Reported Outcomes',
    introBodyText: 'requests that you ensure your information is up to date',
    loggedInSuccessText: 'Thank you for submitting your responses.',
    loggedOutSuccessText: 'Thank you for submitting your responses. You may now close this window.',
    components: [AboutMe1],
  },
  {
    type: 'MEDICATIONS',
    introHeaderText: 'Patient Reported Outcomes',
    introBodyText: 'requests that you ensure your information is up to date',
    loggedInSuccessText: 'Thank you for submitting your responses.',
    loggedOutSuccessText: 'Thank you for submitting your responses. You may now close this window.',
    components: [Medications1, Medications2, Medications3, Medications4],
  },
  {
    type: 'TELEMEDICINE_APPOINTMENT',
    introHeaderText: introHeaderText('telemedicineAppointmentNotificationPage'),
    introBodyText: (
      <>
        <span>
          {i18nTranslator('introBodyText1', 'telemedicineAppointmentNotificationPage')}
        </span>
        <div style={{ marginTop: 25, border: '5px solid black', padding: 5, fontSize: '1.5rem' }}>
          <div>{i18nTranslator('introBodyText2', 'telemedicineAppointmentNotificationPage')}</div>
          <div style={{ marginTop: 10 }}>
            {i18nTranslator('introBodyText3', 'telemedicineAppointmentNotificationPage')}
          </div>
        </div>
      </>
    ),
    loggedInSuccessText: loggedInSuccessText('telemedicineAppointmentNotificationPage'),
    loggedOutSuccessText: loggedOutSuccessText('telemedicineAppointmentNotificationPage'),

    components: [VideoCall1, VideoCall2],
    additionalComponentProps: { attemptAuth: true },
  },
  {
    type: 'NRS',
    introHeaderText: 'Patient Reported Outcomes',
    introBodyText: 'requests that you complete the following symptom assessment',
    loggedInSuccessText: 'Successful symptom assessment!',
    loggedOutSuccessText: 'Thank you for submitting your Assessment. This information is important to your care. You may now close this window.',
    components: [NRS1, NRS2],
  },
  {
    type: 'CLINIC-ACCESS',
    introHeaderText: 'Clinic Access',
    introBodyText: 'has a message for you.',
    loggedInSuccessText: 'Success!',
    loggedOutSuccessText: 'Thank you for submitting your responses. You may now close this window.',
    components: [ClinicAccess],
  },
  {
    type: 'VIDEO-VITALS',
    introHeaderText: 'Video Vitals',
    introBodyText: 'requests that you record your vital sign measurements.',
    loggedInSuccessText: 'Thank you for speaking with us.',
    loggedOutSuccessText: 'Thank you for speaking with us. You may close this window',
    components: [
      VideoVitals1,
      VideoVitals2,
      VideoVitals3,
    ],
  },
  {
    type: 'VIDEO-VITALS-FULL',
    introHeaderText: 'Full Video Vitals',
    introBodyText: 'requests that you record your vital sign measurements.',
    loggedInSuccessText: 'Thank you for speaking with us.',
    loggedOutSuccessText: 'Thank you for speaking with us. You may close this window',
    components: [
      VideoVitalsFull1,
      VideoVitalsFull2,
      VideoVitalsFull3,
    ],
  },
  {
    type: 'APPOINTMENT_REMINDER',
    introHeaderText: 'Appointment reminder',
    introBodyText: 'has a message for you.',
    loggedInSuccessText: '',
    loggedOutSuccessText: 'You may now close this window.',
    components: [AppointmentReminder],
  },
  {
    type: 'APPOINTMENT_CONFIRMATION',
    introHeaderText: introHeaderText('appointmentConfirmationNotificationPage'),
    introBodyText: introBodyText('appointmentConfirmationNotificationPage'),
    loggedOutSuccessText: loggedOutSuccessText('appointmentConfirmationNotificationPage'),
    loggedInSuccessText: loggedInSuccessText('appointmentConfirmationNotificationPage'),
    components: [
      AppointmentConfirmation1,
      AppointmentConfirmation2,
      AppointmentConfirmation3,
      AppointmentConfirmation4,
    ],
  },
  {
    type: 'PAIN-LOG',
    introHeaderText: 'Pain Log',
    introBodyText: 'requests that you record your pain.',
    loggedInSuccessText: 'Thank you for submitting your responses.',
    loggedOutSuccessText: 'Thank you for submitting your responses. You may now close this window.',
    components: [PainLog1, PainLog2],
  },
  {
    type: 'THORACIC-CANCER-SYMPTOMS',
    introHeaderText: 'Thoracic Cancer Symptom Assessment',
    introBodyText: 'requests that you complete the following symptom assessment.',
    loggedInSuccessText: 'Thank you for submitting your symptom assessment. This information is important to your care.',
    loggedOutSuccessText: 'Thank you for submitting your symptom assessment. This information is important to your care. You may now close this window.',
    components: [
      ThoracicCancerSymptoms1,
      ThoracicCancerSymptoms2,
      ThoracicCancerSymptoms3,
      ThoracicCancerSymptoms4,
      ThoracicCancerSymptoms5,
      ThoracicCancerSymptoms6,
      ThoracicCancerSymptoms7,
      ThoracicCancerSymptoms8,
      ThoracicCancerSymptoms9,
      ThoracicCancerSymptoms10,
    ],
  },
  {
    type: 'HEAD-AND-NECK-CANCER-SYMPTOMS',
    introHeaderText: 'Head and Neck Cancer Symptoms Assessment',
    introBodyText: 'requests that you complete the following symptom assessment.',
    loggedInSuccessText: 'Thank you for submitting your symptom assessment. This information is important to your care.',
    loggedOutSuccessText: 'Thank you for submitting your symptom assessment. This information is important to your care. You may now close this window.',
    components: [
      HeadAndNeckCancerSymptoms1,
      HeadAndNeckCancerSymptoms2,
      HeadAndNeckCancerSymptoms3,
      HeadAndNeckCancerSymptoms4,
      HeadAndNeckCancerSymptoms5,
      HeadAndNeckCancerSymptoms6,
      HeadAndNeckCancerSymptoms7,
      HeadAndNeckCancerSymptoms8,
      HeadAndNeckCancerSymptoms9,
      HeadAndNeckCancerSymptoms10,
      HeadAndNeckCancerSymptoms11,
      HeadAndNeckCancerSymptoms12,
      HeadAndNeckCancerSymptoms13,
      HeadAndNeckCancerSymptoms14,
    ],
  },
  {
    type: 'HEAD-AND-NECK-CANCER-SYMPTOMS-NO-VITALS',
    introHeaderText: 'Head and Neck Cancer Symptoms Assessment',
    introBodyText: 'requests that you complete the following symptom assessment.',
    loggedInSuccessText: 'Thank you for submitting your symptom assessment. This information is important to your care.',
    loggedOutSuccessText: 'Thank you for submitting your symptom assessment. This information is important to your care. You may now close this window.',
    components: [
      HeadAndNeckCancerSymptoms1,
      HeadAndNeckCancerSymptoms2,
      HeadAndNeckCancerSymptoms3,
      HeadAndNeckCancerSymptoms4,
      HeadAndNeckCancerSymptoms5,
      HeadAndNeckCancerSymptoms6,
      HeadAndNeckCancerSymptoms7,
      HeadAndNeckCancerSymptoms8,
      HeadAndNeckCancerSymptoms9,
      HeadAndNeckCancerSymptoms10,
      HeadAndNeckCancerSymptoms11,
      HeadAndNeckCancerSymptomsNoVitals12,
      HeadAndNeckCancerSymptomsNoVitals13,
    ],
  },
  {
    type: 'DR-JAMESON-REVIEW',
    introHeaderText: 'Dr. Jameson Review',
    introBodyText: 'requests that you review your experience with Dr. Jameson.',
    loggedInSuccessText: 'Thank you for your feedback.',
    loggedOutSuccessText: 'Thank you for your feedback.',
    components: [
      DrJamesonReview1,
      DrJamesonReview2,
      DrJamesonReview3,
    ],
  },
  {
    type: 'DR-THEODORE-REVIEW',
    introHeaderText: 'Dr. Theodore Review',
    introBodyText: 'requests that you review your experience with Dr. Theodore.',
    loggedInSuccessText: 'Thank you for your feedback.',
    loggedOutSuccessText: 'Thank you for your feedback.',
    components: [
      DrTheodoreReview1,
      DrTheodoreReview2,
      DrTheodoreReview3,
    ],
  },
  {
    type: 'BMI',
    introHeaderText: 'Patient Reported Outcomes',
    introBodyText: 'requests that you complete a weight assessment',
    loggedInSuccessText: 'Successful BMI!',
    loggedOutSuccessText: 'Thank you for submitting your weight assessment. This information is important to your care. You may now close this window.',
    components: [BMI],
  },
  {
    type: 'CAPTURE-INSURANCE-CARD',
    introHeaderText: 'Take pictures of insurance card',
    introBodyText: 'requests that you takes pictures of your insurance card',
    loggedInSuccessText: 'Successfully sent pictures!',
    loggedOutSuccessText: 'Thank you for submitting pictures of your insurance card. You may now close this window.',
    components: [
      CaptureInsuranceCard1,
      CaptureInsuranceCard2,
      CaptureInsuranceCard3,
    ],
  },
  {
    type: 'WOUND',
    introHeaderText: 'Take pictures of wound',
    introBodyText: 'would like to check the current condition of your wound.',
    loggedInSuccessText: 'Successfully sent wound picture(s)!',
    loggedOutSuccessText: 'Thank you for submitting pictures of your wound. You may now close this window.',
    components: [
      Wound1,
      Wound2,
      Wound3,
    ],
  },
  {
    type: 'VITALS-DATA-COLLECTION',
    introHeaderText: 'Vitals Data Collection',
    introBodyText: 'requests that you complete the vitals-data-collection assessment form',
    loggedInSuccessText: 'Thank you for your feedback.',
    loggedOutSuccessText: 'Thank you for completing. You may now close this window.',
    components: [
      VitalsDataCollection1,
      VitalsDataCollection2,
      VitalsDataCollection3,
      VitalsDataCollection4,
      VitalsDataCollection5,
      VitalsDataCollection6,
      VitalsDataCollection7,
      VitalsDataCollection8,
      VitalsDataCollection9,
      VitalsDataCollection10,
    ],
  },
  {
    type: 'FULL-DATA-COLLECTION',
    introHeaderText: 'Full Vitals Data Collection',
    introBodyText: 'requests that you complete the full-data-collection assessment form',
    loggedInSuccessText: 'Thank you for your feedback.',
    loggedOutSuccessText: 'Thank you for completing. You may now close this window.',
    components: [
      FullDataCollection1,
      FullDataCollection2,
      FullDataCollection3,
      FullDataCollection4,
      FullDataCollection5,
      FullDataCollection6,
      FullDataCollection7,
      FullDataCollection8,
      FullDataCollection9,
      FullDataCollection10,
      FullDataCollection11,
    ],
  },
  {
    type: 'CONTACT-SPO2-DATA-COLLECTION',
    introHeaderText: 'Contact Spo2 Data Collection',
    introBodyText: 'requests that you complete the contact-spo2-data-collection assessment form.',
    loggedInSuccessText: 'Thank you for your feedback.',
    loggedOutSuccessText: 'Thank you for completing. You may now close this window.',
    components: [
      ContactSpo2DataCollection1,
      ContactSpo2DataCollection2,
      // ContactSpo2DataCollection3,
      ContactSpo2DataCollection4,
      ContactSpo2DataCollection5,
      ContactSpo2DataCollection6,
      ContactSpo2DataCollection7,
      // ContactSpo2DataCollection8,
      // ContactSpo2DataCollection9,
      ContactSpo2DataCollection10,
      ContactSpo2DataCollection11,
      ContactSpo2DataCollection12,
      ContactSpo2DataCollection13,
    ],
  },
  {
    type: 'CONTINUOUS-BP-DATA-COLLECTION',
    introHeaderText: 'Continuous BP Data Collection',
    introBodyText: 'requests that you complete the continuous blood pressure assessment form.',
    loggedInSuccessText: 'Thank you for your feedback.',
    loggedOutSuccessText: 'Thank you for completing. You may now close this window.',
    components: [
      ContinuousBPDataCollectionManualBp,
      ContinuousBPDataCollection1,
      ContinuousBPDataCollectionInstructions,
      ContinuousBPDataCollection2,
      ContinuousBPDataCollection3,
      ContinuousBPDataCollection4,
      ContinuousBPDataCollection5,
      ContinuousBPDataCollection6,
      ContinuousBPDataCollection7,
      ContinuousBPDataCollection8,
      ContinuousBPDataCollection9,
      ContinuousBPDataCollection10,
      ContinuousBPDataCollection11,
    ],
  },
  {
    type: 'SECONDARY-BP-DATA-COLLECTION',
    introHeaderText: 'Continuous BP Data Collection',
    introBodyText: 'requests that you complete the continuous blood pressure assessment form.',
    loggedInSuccessText: 'Thank you for your feedback.',
    loggedOutSuccessText: 'Thank you for completing. You may now close this window.',
    components: [
      ContinuousBPDataCollectionManualBp,
      ContinuousBPDataCollection1,
      ContinuousBPDataCollectionInstructions,
      ContinuousBPDataCollection2,
      ContinuousBPDataCollection3,
      ContinuousBPDataCollectionManualBp,
      ContinuousBPDataCollection4,
      ContinuousBPDataCollection5,
      ContinuousBPDataCollection6,
      ContinuousBPDataCollection7,
      ContinuousBPDataCollection8,
      ContinuousBPDataCollection9,
      ContinuousBPDataCollection10,
      ContinuousBPDataCollection11,
    ],
  },
  {
    type: 'DUAL-CAMERA-BP-DATA-COLLECTION',
    introHeaderText: 'Continuous BP Data Collection',
    introBodyText: 'requests that you complete the continuous blood pressure assessment form.',
    loggedInSuccessText: 'Thank you for your feedback.',
    loggedOutSuccessText: 'Thank you for your feedback. You may now close this window.',
    components: [
      continuousBpDataCollectionCalibrationMode,
      ContinuousBPDataCollectionManualBp,
      ContinuousBPDataCollectionCameraMode,
      ContinuousBPDataCollection1,
      ContinuousBPDataCollectionInstructions,
      ContinuousBPDataCollection2,
      ContinuousBPDataCollection3,
      ContinuousBPDataCollectionManualBp,
      ContinuousBPDataCollection4,
      ContinuousBPDataCollection5,
      ContinuousBPDataCollection6,
      ContinuousBPDataCollection7,
      ContinuousBPDataCollection8,
      ContinuousBPDataCollection9,
      ContinuousBPDataCollection10,
      ContinuousBPDataCollection11,
    ],
  },
  {
    type: 'DIABETES-INITIAL',
    introHeaderText: 'Patient Reported Outcomes',
    introBodyText: 'requests that you complete the following symptom assessment.',
    loggedInSuccessText: 'Thank you for submitting your symptom assessment. This information is important to your care.',
    loggedOutSuccessText: 'Thank you for submitting your symptom assessment. This information is important to your care. You may now close this window.',
    components: [
      DiabetesInitial1,
      DiabetesInitial2,
      DiabetesInitial3,
      DiabetesInitial4,
      DiabetesInitial5,
      DiabetesInitial6,
      DiabetesInitial7,
      DiabetesInitial8,
      DiabetesInitial9,
      DiabetesInitial10,
      DiabetesInitial11,
      DiabetesInitial12,
      DiabetesInitial13,
      DiabetesInitial14,
      DiabetesInitial15,
      DiabetesInitial16,
    ],
  },
  {
    type: 'DIABETES-DAILY',
    introHeaderText: 'Patient Reported Outcomes',
    introBodyText: 'requests that you complete the following symptom assessment.',
    loggedInSuccessText: 'Thank you for submitting your symptom assessment. This information is important to your care.',
    loggedOutSuccessText: 'Thank you for submitting your symptom assessment. This information is important to your care. You may now close this window.',
    components: [
      DiabetesDaily1,
      DiabetesDaily2,
      DiabetesDaily3,
      DiabetesDaily4,
      DiabetesDaily5,
      DiabetesDaily6,
      DiabetesDaily7,
    ],
  },
  {
    type: 'HYPERTENSION-ASSESSMENT-INITIAL',
    introHeaderText: 'Hypertension Initial Assessment',
    introBodyText: 'requests that you take the hypertension assessment.',
    loggedInSuccessText: 'Successfully sent assessment!',
    loggedOutSuccessText: 'Thank you for submitting your assessment. You may now close this window.',
    components: [
      HypertensionAssessmentInitial1,
      HypertensionAssessmentInitial2,
      HypertensionAssessmentInitial3,
      HypertensionAssessmentInitial4,
      HypertensionAssessmentInitial5,
      HypertensionAssessmentInitial6,
      HypertensionAssessmentInitial7,
      HypertensionAssessmentInitial8,
      HypertensionAssessmentInitial9,
      HypertensionAssessmentInitial10,
      HypertensionAssessmentInitial11,
      HypertensionAssessmentInitial12,
      HypertensionAssessmentInitial13,
      HypertensionAssessmentInitial14,
    ],
  },
  {
    type: 'HYPERTENSION-ASSESSMENT-DAILY',
    introHeaderText: 'Hypertension Daily Assessment',
    introBodyText: 'requests that you take the hypertension daily assessment.',
    loggedInSuccessText: 'Successfully sent assessment!',
    loggedOutSuccessText: 'Thank you for submitting your assessment. You may now close this window.',
    components: [
      HypertensionAssessmentDaily1,
      HypertensionAssessmentDaily2,
      HypertensionAssessmentDaily3,
      HypertensionAssessmentDaily4,
      HypertensionAssessmentDaily5,
      HypertensionAssessmentDaily6,
      HypertensionAssessmentDaily7,
      HypertensionAssessmentDaily8,
    ],
  },
  {
    type: 'HYPERTENSION-ASSESSMENT-DAILY-NO-VITALS',
    introHeaderText: 'Hypertension Daily Assessment',
    introBodyText: 'requests that you take the hypertension daily assessment.',
    loggedInSuccessText: 'Successfully sent assessment!',
    loggedOutSuccessText: 'Thank you for submitting your assessment. You may now close this window.',
    components: [
      HypertensionAssessmentDaily5,
      HypertensionAssessmentDaily6,
      HypertensionAssessmentDaily7,
      HypertensionAssessmentDailyNoVitals8,
    ],
  },
  {
    type: 'HEART-FAILURE-INITIAL',
    introHeaderText: 'Initial Heart Failure Assessment',
    introBodyText: 'requests that you complete the following assessment.',
    loggedInSuccessText: 'Thank you for submitting your assessment. This information is important to your care.',
    loggedOutSuccessText: 'Thank you for submitting your assessment. This information is important to your care. You may now close this window.',
    components: [
      HeartFailureInitial1,
      HeartFailureInitial2,
      HeartFailureInitial3,
      HeartFailureInitial4,
      HeartFailureInitial5,
      HeartFailureInitial6,
      HeartFailureInitial7,
      HeartFailureInitial8,
      HeartFailureInitial9,
      HeartFailureInitial10,
      HeartFailureInitial11,
      HeartFailureInitial12,
      HeartFailureInitial13,
      HeartFailureInitial14,
      HeartFailureInitial15,
    ],
  },
  {
    type: 'HEART-FAILURE-DAILY',
    introHeaderText: 'Daily Heart Failure Assessment',
    introBodyText: 'requests that you complete the following assessment.',
    loggedInSuccessText: 'Thank you for submitting your assessment. This information is important to your care.',
    loggedOutSuccessText: 'Thank you for submitting your assessment. This information is important to your care. You may now close this window.',
    components: [
      HeartFailureDaily1,
      HeartFailureDaily2,
      HeartFailureDaily3,
      HeartFailureDaily4,
      HeartFailureDaily5,
      HeartFailureDaily6,
    ],
  },
  {
    type: 'EDUCATION-ASSESSMENT',
    introHeaderText: 'Education Assessment',
    introBodyText: 'requests that you view an important message.',
    loggedInSuccessText: 'Thank you for submitting your assessment. This information is important to your care.',
    loggedOutSuccessText: 'Thank you for submitting your assessment. This information is important to your care. You may now close this window.',
    components: [
      EducationAssessment1,
      EducationAssessment2,
    ],
  },
  {
    type: 'PATIENT-DEMOGRAPHICS',
    introHeaderText: introHeaderText('demographicsNotificationPage'),
    introBodyText: introBodyText('demographicsNotificationPage'),
    loggedOutSuccessText: loggedOutSuccessText('demographicsNotificationPage'),
    loggedInSuccessText: loggedInSuccessText('demographicsNotificationPage'),
    components: [
      PatientDemographics1,
      PatientDemographics2,
      PatientDemographics3,
      PatientDemographics4,
      PatientDemographics5,
      PatientDemographics6,
      PatientDemographics7,
      PatientDemographics8,
      PatientDemographics9,
    ],
  },
  {
    type: 'WOUND-CASA-COLINA',
    introHeaderText: introHeaderText('woundCasaColinaNotificationPage'),
    introBodyText: introBodyText('woundCasaColinaNotificationPage'),
    loggedOutSuccessText: loggedOutSuccessText('woundCasaColinaNotificationPage'),
    loggedInSuccessText: loggedInSuccessText('woundCasaColinaNotificationPage'),
    components: [
      WoundCasaColina1,
      // WoundCasaColina2,
      WoundCasaColina3,
      WoundCasaColina4,
      WoundCasaColina5,
    ],
  },
  {
    type: 'VITAL-CORE',
    introHeaderText: 'Informed Vital Core',
    introBodyText: 'requests that you measure your vitals.',
    loggedInSuccessText: (
      <div>
        <p><strong>Thank you.</strong></p>
        <p>Your results have been submitted to your healthcare provider.</p>
        <p>Please <strong>close this browser</strong> tab.</p>
      </div>
    ),
    loggedOutSuccessText: (
      <div>
        <p><strong>Thank you.</strong></p>
        <p>Your results have been submitted to your healthcare provider.</p>
        <p>Please <strong>close this browser</strong> tab.</p>
      </div>
    ),
    components: [
      VitalCore1,
      VitalCore2,
      VitalCore3,
      VitalCore4,
      VitalCore5,
      VitalCore6,
      VitalCore7,
      VitalCore8,
      VitalCore9,
    ],
  },
  {
    type: 'VITAL-CORE-RR',
    introHeaderText: 'Informed Vital Core',
    introBodyText: 'requests that you measure your respiratory rate.',
    loggedInSuccessText: (
      <div>
        <p><strong>Thank you.</strong></p>
        <p>Your results have been submitted to your healthcare provider.</p>
        <p>Please <strong>close this browser</strong> tab.</p>
      </div>
    ),
    loggedOutSuccessText: (
      <div>
        <p><strong>Thank you.</strong></p>
        <p>Your results have been submitted to your healthcare provider.</p>
        <p>Please <strong>close this browser</strong> tab.</p>
      </div>
    ),
    components: [
      VitalCoreRR1,
      VitalCoreRR2,
      VitalCoreRR3,
      VitalCoreRR4,
      VitalCoreRR5,
      VitalCoreRR6,
      VitalCoreRR7,
      VitalCoreRR8,
      VitalCoreRR9,
    ],
  },
  {
    type: 'VITAL-CORE-4-VITALS',
    introHeaderText: 'Informed Vital Core',
    introBodyText: 'requests that you measure your vitals.',
    loggedInSuccessText: (
      <div>
        <p><strong>Thank you.</strong></p>
        <p>Your results have been submitted to your healthcare provider.</p>
        <p>Please <strong>close this browser</strong> tab.</p>
      </div>
    ),
    loggedOutSuccessText: (
      <div>
        <p><strong>Thank you.</strong></p>
        <p>Your results have been submitted to your healthcare provider.</p>
        <p>Please <strong>close this browser</strong> tab.</p>
      </div>
    ),
    components: [
      VitalCore1,
      VitalCore2,
      VitalCore3,
      VitalCore4,
      VitalCore5,
      VitalCore6,
      VitalCore7,
      VitalCore8,
      VitalCore4VitalsResults,
    ],
  },
  {
    type: 'VITAL-CORE-BENCH-HR',
    introHeaderText: 'Informed Vital Core Benchmark HR',
    introBodyText: 'requests that you measure your vitals measurements.',
    loggedInSuccessText: (
      <div>
        <p>Thank you for submitting your vitals.</p>
        <p>Please <strong>close this browser</strong> tab to remove your vital sign results from your browser's memory.</p>
      </div>
    ),
    loggedOutSuccessText: (
      <div>
        <p>Thank you for submitting your vitals.</p>
        <p>Please <strong>close this browser</strong> tab to remove your vital sign results from your browser's memory.</p>
      </div>
    ),
    components: [
      VitalCoreBenchHr5,
      VitalCoreBenchHr6,
      VitalCoreBenchHr7,
      VitalCoreBenchHrInfo,
      VitalCoreBenchHrBmi,
      VitalCoreBenchHrMakeup,
      VitalCoreBenchHrFitzpatrick,
      VitalCoreBenchHrFacial,
      VitalCoreBenchHrPair,
      VitalCoreBenchHrPreview,
      VitalCoreBenchHr8,
      VitalCoreBenchHr9,
    ],
  },
  {
    type: 'IVC-RR-CLINICAL-STUDY',
    introHeaderText: 'Informed Vital Core',
    introBodyText: 'requests that you measure your vitals measurements.',
    loggedInSuccessText: (
      <div>
        <p>Thank you for submitting your vitals.</p>
        <p>Please <strong>close this browser</strong> tab to remove your vital sign results from your browser's memory.</p>
      </div>
    ),
    loggedOutSuccessText: (
      <div>
        <p>Thank you for submitting your vitals.</p>
        <p>Please <strong>close this browser</strong> tab to remove your vital sign results from your browser's memory.</p>
      </div>
    ),
    components: [
      IvcRRClinicalStudy1,
      IvcRRClinicalStudy2,
      IvcRRClinicalStudy3
    ],
  },
  {
    type: 'HUMAN-FACTORS-FOR-IVC-APP',
    introHeaderText: 'Human Factors for IVC App',
    introBodyText: 'requests that you complete the following questionnaire regarding your testing experience.',
    loggedInSuccessText: 'Thank you for providing us with your feedback. This information is important to your care.',
    loggedOutSuccessText: 'Thank you for providing us with your feedback. This information is important to your care. You may now close this window.',
    components: [
      HumanFactorsForIvcApp1,
      HumanFactorsForIvcApp2,
      HumanFactorsForIvcApp3,
      HumanFactorsForIvcApp4,
    ],
  },
  {
    type: 'GI-ONCOLOGY',
    introHeaderText: 'GI Oncology',
    introBodyText: 'requests that you complete the following assessment.',
    loggedInSuccessText: 'Thank you for submitting your symptom assessment. This information is important to your care.',
    loggedOutSuccessText: 'Thank you for submitting your symptom assessment. This information is important to your care. You may now close this window.',
    components: [
      GIOncology1,
      GIOncology2,
      GIOncology3,
      GIOncology4,
      GIOncology5,
      GIOncology6,
    ],
  },
  {
    type: 'RESPIRATORY-ONCOLOGY',
    introHeaderText: 'Respiratory Oncology',
    introBodyText: 'requests that you complete the following assessment.',
    loggedInSuccessText: 'Thank you for submitting your symptom assessment. This information is important to your care.',
    loggedOutSuccessText: 'Thank you for submitting your symptom assessment. This information is important to your care. You may now close this window.',
    components: [
      RespiratoryOncology1,
      RespiratoryOncology2,
      RespiratoryOncology3,
      RespiratoryOncology4,
    ],
  },
  {
    type: 'NEUTROPENIA',
    introHeaderText: 'Neutropenia',
    introBodyText: 'requests that you complete the following assessment.',
    loggedInSuccessText: 'Thank you for submitting your symptom assessment. This information is important to your care.',
    loggedOutSuccessText: 'Thank you for submitting your symptom assessment. This information is important to your care. You may now close this window.',
    components: [
      Neutropenia1,
      Neutropenia2,
    ],
  },
  {
    type: 'GAD7',
    introHeaderText: 'GAD7',
    introBodyText: 'has sent you a form to fill out',
    loggedInSuccessText: 'Successful GAD7 Report!',
    loggedOutSuccessText: 'Thank you for submitting your GAD7 Assessment. This information is important to your care. You may now close this window.',
    components: [GAD71, GAD72],
  },
  {
    type: 'BLOOMKIDZ-NEW-CLIENT',
    introHeaderText: 'BloomKidz New Client',
    introBodyText: (
      <>has sent you <span style={{ color: bloomkidzStyles.primaryColor }}>the New Client Information</span> form to fill out.</>
    ),
    loggedInSuccessText: 'Thank you for submitting your assessment. This information is important to your care.',
    loggedOutSuccessText: 'Thank you for submitting your assessment. This information is important to your care. You may now close this window.',
    components: [
      BloomkidzNewClient1,
      BloomkidzNewClient2,
      BloomkidzNewClient3,
      BloomkidzNewClient4,
      BloomkidzNewClient5,
      BloomkidzNewClient6,
      BloomkidzNewClient7,
      BloomkidzNewClient8,
    ],
  },
  {
    type: 'BLOOMKIDZ-MEAL-AND-NUTRITION',
    introHeaderText: 'Login',
    introBodyText: (
      <>has sent you <span style={{ color: bloomkidzStyles.primaryColor }}>the Meal & Nutrition</span> form to fill out.</>
    ),
    loggedInSuccessText: 'Thank you for submitting your assessment. This information is important to your care.',
    loggedOutSuccessText: 'Thank you for submitting your assessment. This information is important to your care. You may now close this window.',
    components: [
      BloomkidzMealAndNutrition1,
      BloomkidzMealAndNutrition2,
      BloomkidzMealAndNutrition3,
      BloomkidzMealAndNutrition4,
    ],
  },
  {
    type: 'BLOOMKIDZ-CLIENT-WELLNESS-PROFILE',
    introHeaderText: 'BloomKidz Client Wellness Profile',
    introBodyText: (
      <>has sent you <span style={{ color: bloomkidzStyles.primaryColor }}>the BloomKidz Client Wellness Profile</span> form to fill out.</>
    ),
    loggedInSuccessText: 'Thank you for submitting your assessment. This information is important to your care.',
    loggedOutSuccessText: 'Thank you for submitting your assessment. This information is important to your care. You may now close this window.',
    components: [
      BloomkidzClientWellnessProfile1,
      BloomkidzClientWellnessProfile2,
      BloomkidzClientWellnessProfile3,
      BloomkidzClientWellnessProfile4,
      BloomkidzClientWellnessProfile5,
      BloomkidzClientWellnessProfile6,
    ]
  },
  {
    type: 'BLOOMKIDZ-PARENT-GUARDIAN-INFORMATION',
    introHeaderText: 'Login',
    introBodyText: (
      <>has sent you <span style={{ color: bloomkidzStyles.primaryColor }}>the Parent Guardian Information</span> form to fill out.</>
    ),
    loggedInSuccessText: 'Thank you for submitting your assessment. This information is important to your care.',
    loggedOutSuccessText: 'Thank you for submitting your assessment. This information is important to your care. You may now close this window.',
    components: [
      bloomkidzParentGuardianInformation1,
      bloomkidzParentGuardianInformation2,
      bloomkidzParentGuardianInformation3,
      bloomkidzParentGuardianInformation4,
      bloomkidzParentGuardianInformation5,
      bloomkidzParentGuardianInformation6,
      bloomkidzParentGuardianInformation7,
      bloomkidzParentGuardianInformation8,
      bloomkidzParentGuardianInformation9,
      bloomkidzParentGuardianInformation10,
    ],
  },
  {
    type: 'BLOOMKIDZ-MEDICAL-HISTORY',
    introHeaderText: 'BloomKidz Medical History',
    introBodyText: (
      <>has sent you <span style={{ color: bloomkidzStyles.primaryColor }}>the BloomKidz Medical History</span> form to fill out.</>
    ),
    loggedInSuccessText: 'Thank you for submitting your assessment. This information is important to your care.',
    loggedOutSuccessText: 'Thank you for submitting your assessment. This information is important to your care. You may now close this window.',
    components: [
      BloomkidzMedicalHistory1,
      BloomkidzMedicalHistory2,
      BloomkidzMedicalHistory3,
      BloomkidzMedicalHistory4,
      BloomkidzMedicalHistory5,
      BloomkidzMedicalHistory6,
      BloomkidzMedicalHistory7,
      BloomkidzMedicalHistory8,
      BloomkidzMedicalHistory9,
      BloomkidzMedicalHistory10,
      BloomkidzMedicalHistory11,
      BloomkidzMedicalHistory12,
      BloomkidzMedicalHistory13,
    ]
  },
  {
    type: 'BLOOMKIDZ-SIGNATURES',
    introHeaderText: 'BloomKidz Signatures',
    introBodyText: (
      <>has sent you <span style={{ color: bloomkidzStyles.primaryColor }}>the BloomKidz Signatures</span> form to fill out.</>
    ),
    loggedInSuccessText: 'Thank you for submitting your assessment. This information is important to your care.',
    loggedOutSuccessText: 'Thank you for submitting your assessment. This information is important to your care. You may now close this window.',
    components: [
      BloomkidzSignatures1,
      BloomkidzSignatures2,
      BloomkidzSignatures3,
      BloomkidzSignatures4,
      BloomkidzSignatures5,
      BloomkidzSignatures6,
      BloomkidzSignatures7,
      BloomkidzSignatures8,
      BloomkidzSignatures9,
      BloomkidzSignatures10,
      BloomkidzSignatures11,
      BloomkidzSignatures12,
      BloomkidzSignatures13,
      BloomkidzSignatures14,
      BloomkidzSignatures15,
      BloomkidzSignatures16,
      BloomkidzSignatures17,
      BloomkidzSignatures18,
      BloomkidzSignatures19,
      BloomkidzSignatures20,
      BloomkidzSignatures21,
    ],
  },
];

export default notificationTracks;
