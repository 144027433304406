import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Button } from '@material-ui/core';

import { WaterDrop } from '../lib/icons';
import ProWrapper from '../lib/pro-wrapper';

import Page from './page';

import { updatePRO } from '../state/pro-forms';

const styles = {
  button: {
    margin: 5,
    width: 150,
    height: 40,
    fontSize: 16,
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  titleGroup: {
    margin: 25,
  },
  text: {
    fontSize: '22px',
  },
};

class Diabetes15 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      hypoAndHyperglycemiaKnowledge: null,
    };

    this.handleContinue = this.handleContinue.bind(this);
  }

  handleContinue() {
    this.props.updatePRO({
      type: 'diabetesInitial',
      position: this.getTrackIndex(),
      value: this.state });
    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const { hypoAndHyperglycemiaKnowledge } = this.state;

    return (
      <ProWrapper
        header="Blood Sugar"
        currentPage={this.getCurrentPage()}
        totalPages={this.getTotalPages()}
        onContinue={this.handleContinue}
        icon={<WaterDrop />}
        disabled={hypoAndHyperglycemiaKnowledge === null}
      >
        <div style={styles.titleGroup}>
          <p style={styles.text}>Do you know the symptoms of hypoglycemia and hyperglycemia?</p>
          <div style={styles.buttonGroup}>
            <Button size="large" style={styles.button} variant="contained" color={hypoAndHyperglycemiaKnowledge ? 'primary' : 'default'} onClick={() => this.setState({ hypoAndHyperglycemiaKnowledge: true })}>Yes</Button>
            <Button size="large" style={styles.button} variant="contained" color={hypoAndHyperglycemiaKnowledge === false ? 'primary' : 'default'} onClick={() => this.setState({ hypoAndHyperglycemiaKnowledge: false })}>No</Button>
          </div>
        </div>
      </ProWrapper>
    );
  }
}

function mapStateToProps(state) {
  const { user, proForms: { diabetesInitial } } = state;
  return { user, diabetesInitial };
}

Diabetes15.proptypes = {
  user: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(Diabetes15);
