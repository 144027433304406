/* eslint-disable eqeqeq */
/* eslint-disable no-bitwise */
/* eslint-disable no-await-in-loop */
import rawr from 'rawr';
import transport from 'rawr/transports/worker';

const MAX_TIME_THRESHOLD_MS = 60000;
const MAX_TIME_THRESHOLD_MS_FOR_DISPLAY = 60000;
const MIN_FRAME_COUNT = 550;
const TARGET_FRAME_INTERVAL_MS = 50;

const STANDARD_CROP_HEIGHT = 300;
const STANDARD_CROP_WIDTH = 225;
const STANDARD_EDGE_SIZE = 50;
const LINE_WIDTH_PCT = 0.01;

let preparationVideoRAF;
let preparationVideoVFC;
let preparationVideoElement;
let videoFrameCallback;
let patientVideo;
let onframeTimer;
let onframeRAF;
let downloadTimer;
let algorithmConfig = {};
let getConfigString;
let getVersionString;
let startSession;
let endSession;
let detectVitals;
let previewSession;
let getRunToken;
let authorizeVitals;
let authorized = false;
let previewing = false;

let rawrPeer;
let webWorker;

let start_timestamp_ms = -1;

let cacheFrames = false;
let frameCount = 0;
let cachedFrameCount = 0;
let processedFrameCount = 0;
let processingLeft = 0;
let processing = false;

let lastSigns;
let lastTrackingInfo;
let tracking = false;
let onceReady = false;
let needSegmentedImage = false;
let brEnabled;
let hrEnabled;
let bpEnabled;
let spo2Enabled;
const ovalWarningList = ['W-RES-080','W-RES-079','W-RES-078','W-RES-073'];

let startTimestamp;

let front_timestamps = fixedLengthArray(30);
let rear_timestamps = fixedLengthArray(30);
let detectTimestamps = [];
let responseTimestamps = [];
let averageFrameRate = 0;

let shouldProcessFrame = true; // flag used to prevent frame collecting and processing while vitals collection restarts
let devMode = false;
let preventSessionOnUnmount = false;
let guideBoxColor = 'green';

// FaceDetector Options
const EXTERNAL_SKIP_FRAME = 30;
let externalFaceDetection = true;
let useFaceMesh = false;
let bboxHeightAdjustment = 33.0;
let bboxWidthAdjustment = 0.0;

let fax, fay, faw, fah;
// let detectionTimestamp = 0;
let detector;

// used to prevent starting a new session before endSession() returns if a user navigates away
// from the video vitals page and comes back, either from using browser controls or clicking
// the retry button
let resetting = false;

export const loadDetector = async () => {
  if (!externalFaceDetection)
  {
    detector = null;
    console.log("Use internal face detection")
  }
  else if (useFaceMesh)
  {
    const model = window.faceLandmarksDetection.SupportedModels.MediaPipeFaceMesh;
    const detectorConfig = {
      runtime: 'mediapipe',
      solutionPath: 'https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh',
      maxFaces: 1,
    };
    detector = await window.faceLandmarksDetection.createDetector(model, detectorConfig);
    console.log("MediaPipe FaceMesh Detector Loaded")
  }
  else
  {
    const model = window.faceDetection.SupportedModels.MediaPipeFaceDetector;
    const detectorConfig = {
      runtime: 'mediapipe',
      solutionPath: 'https://cdn.jsdelivr.net/npm/@mediapipe/face_detection',
    };
    detector = await window.faceDetection.createDetector(model, detectorConfig);
    console.log("MediaPipe Face Detector Loaded")
  }
};

let faceDetectionCounter = EXTERNAL_SKIP_FRAME;

function fixedLengthArray(length) {
  const array = [];
  array.push = function () {
    if (this.length >= length) {
        this.shift();
    }
    return Array.prototype.push.apply(this,arguments);
  }
  return array;
}

export function getDimensions(video) {
  const scale = STANDARD_CROP_HEIGHT / video.videoHeight;
  const lineWidth = Math.round(LINE_WIDTH_PCT * video.videoHeight);

  // const pvH = STANDARD_CROP_HEIGHT;
  // const pvW = Math.round((pvH / video.videoHeight) * video.videoWidth);
  const scaledW = Math.round((STANDARD_CROP_HEIGHT / video.videoHeight) * video.videoWidth);
  const STANDARD_DISPLAY_WIDTH = Math.round((STANDARD_CROP_WIDTH * video.videoHeight) / STANDARD_CROP_HEIGHT);
  
  const displayCropH = video.videoHeight;
  const displayCropW = Math.min(video.videoWidth, (video.videoHeight * STANDARD_CROP_WIDTH) / STANDARD_CROP_HEIGHT);

  const cropH = STANDARD_CROP_HEIGHT;
  const cropW = Math.min(scaledW, STANDARD_CROP_WIDTH);

  let cropX = (scaledW / 2) - (cropW / 2);
  let displayCropX = (video.videoWidth / 2) - (displayCropW / 2);

  if (scaledW > STANDARD_CROP_WIDTH) {
    cropX = (scaledW / 2) - (STANDARD_CROP_WIDTH / 2);
    displayCropX = (video.videoWidth / 2) - (STANDARD_DISPLAY_WIDTH / 2);
  }

  const edgeSize = Math.round((STANDARD_EDGE_SIZE * video.videoHeight) / STANDARD_CROP_HEIGHT);

  const cropY = 0;
  const displayCropY = 0;
  const overlayX = displayCropX + edgeSize;
  const overlayY = displayCropY + edgeSize;
  const overlayW = displayCropW - (edgeSize * 2);
  const overlayH = displayCropH - (edgeSize * 2);
  const dimensions = {
    displayCropH,
    displayCropW,
    dipslayW: video.videoWidth,
    cropH,
    cropW,
    scaledW,
    displayCropY,
    displayCropX,
    cropY,
    cropX,
    overlayX,
    overlayY,
    overlayW,
    overlayH,
    lineWidth,
    edgeSize,
    scale,
  };
  return dimensions;
}

export function toggleDevMode() {
  devMode = !devMode;
}

export function setGuideBoxColor(color) {
  guideBoxColor = color;
}

export function killWebWorker() {
  webWorker.terminate();
  webWorker = null;
  onceReady = false;
  if (patientVideo && videoFrameCallback) {
    patientVideo.cancelVideoFrameCallback(videoFrameCallback);
  } else {
    clearTimeout(onframeTimer);
  }
  clearInterval(downloadTimer);
}

function stopTimer() {
  clearInterval(downloadTimer);
}

export async function initialSessionPreview(callbacks) {
  const { vrGetAuthToken, vrLicenseError, vrOnAuthorizeVitals } = callbacks;

  if (!authorized) {
    try {
      let token;
      try {
        const authTokenResp = await vrGetAuthToken(getRunToken);
        token = authTokenResp.token;
      } catch (e) {
        if (e.status === 401 || e.status === 403) {
          vrLicenseError('There is not an active licence key to use Informed Vital Core.');
          return;
        } else {
          vrLicenseError('Unable to verify the license key to use Informed Vital Core due to network error.');
          return;
        }
      }

      let authorizedVitals = await authorizeVitals(token);
      authorizedVitals = JSON.parse(authorizedVitals);

      vrOnAuthorizeVitals(authorizedVitals);

      if (authorizedVitals.status !== 'authorized') {
        vrLicenseError('There is not an active licence key to use Informed Vital Core.');
        return;
      }

      if (!authorizedVitals.vitals.BR || !authorizedVitals.vitals.HR) {
        vrLicenseError('The required vitals for the app have not been enabled.');
        return;
      }
    
      authorized = true;
    } catch (e) {
      vrLicenseError('Unable to verify the license key to use Informed Vital Core.');
    }
  }
  await previewSession();
  previewing = true;
  cacheFrames = true;
}

async function endPreview() {
  // if processing left, wait for it to finish
  const startTime = Date.now();
  const timeout = 1000;
  while (cachedFrameCount !== processedFrameCount) {
    if (Date.now() - startTime >= timeout) break;
    await new Promise(r => setTimeout(r, 100));
  }
  await endSession();

  const noResettingCacheFrames = true;
  reset(noResettingCacheFrames);
}

function containsFaceWarnings(commaSeparatedString) {
  const items = commaSeparatedString.split(',');
  return ovalWarningList.some(warning => items.includes(warning));
}

function reset(noResettingCacheFrames = false) {
  stopTimer();
  // mainRun();
  cacheFrames = noResettingCacheFrames ? cacheFrames : false;
  tracking = false;
  start_timestamp_ms = -1;

  frameCount = 0;
  cachedFrameCount = 0;
  processedFrameCount = 0;
  front_timestamps.length = 0;
  rear_timestamps.length = 0;
  detectTimestamps = [];
  responseTimestamps = [];

  lastTrackingInfo = null;
  processingLeft = 0;
  lastSigns = null;
  lastTrackingInfo = null;
  startTimestamp = null;

  preventSessionOnUnmount = false;
  guideBoxColor = 'green';
  resetting = false;
}

export function setVitalsRunnerStateForReload() {
  cacheFrames = false;
  tracking = false;
  start_timestamp_ms = -1;

  frameCount = 0;
  cachedFrameCount = 0;
  processedFrameCount = 0;
  front_timestamps.length = 0;
  rear_timestamps.length = 0;
  detectTimestamps = [];
  responseTimestamps = [];

  lastTrackingInfo = null;
  processingLeft = 0;
  lastSigns = null;
  lastTrackingInfo = null;
  startTimestamp = null;

  guideBoxColor = 'green';
  resetting = false;
  onceReady = false;
}

export async function endSessionJS(callbacks) {
  const {
    vrOnend,
    vrOnprocessing,
  } = callbacks;

  const timeBeforeEndSession = Date.now();

  if (patientVideo && videoFrameCallback) {
    patientVideo.cancelVideoFrameCallback(videoFrameCallback);
  } else {
    clearTimeout(onframeTimer);
  }

  let finalSigns = await endSession();
  // convert from bigint to number
  finalSigns.timestamp = Number(finalSigns.timestamp);
  finalSigns.detectTimestamps = detectTimestamps;
  finalSigns.responseTimestamps = responseTimestamps;
  finalSigns.averageFrameRate = averageFrameRate;
  console.log("Session ended. Final results: ", finalSigns);
  processing = false;
  if (vrOnprocessing) {
    vrOnprocessing(false);
  }
  if (vrOnend) {
    const durationToEndSession = Date.now() - timeBeforeEndSession;
    finalSigns.durationToEndSession = durationToEndSession;
    vrOnend(finalSigns);
  }

  reset();
}

// Front camera
function detectVitalsJS(imgBitmap, width, height, timestamp, segmentedImageNeeded) {
  if (cachedFrameCount === 0) {
    start_timestamp_ms = timestamp;
  }

  let frameInfo = {
    width: width,
    height: height,
    timestamp: timestamp
  }
  detectTimestamps.push(timestamp);

  const faceInfo = {
    height: fah,
    width: faw,
    x: fax,
    y: fay,
    use: externalFaceDetection
  };

  // detectVitals(frameInfo, imgData, faceInfo); // async from webworker
  detectVitals(frameInfo, imgBitmap, faceInfo, segmentedImageNeeded, { postMessageOptions: [imgBitmap] }); // async from webworker

  cachedFrameCount++; // only keeping count for front camera

  return;
}

function enabledVitalsCollected() {
  if (lastSigns == null) return;
  let enabledVitalsCollected = true;
  if (hrEnabled) {
    enabledVitalsCollected &= lastSigns.hrSignalPercent == 1.0;
  }
  if (brEnabled) {
    enabledVitalsCollected &= lastSigns.brSignalPercent == 1.0;
  }
  if (bpEnabled) {
    enabledVitalsCollected &= lastSigns.brSignalPercent == 1.0;
  }
  if (spo2Enabled) {
    enabledVitalsCollected &= lastSigns.brSignalPercent == 1.0;
  }
  return enabledVitalsCollected;
}

function setTimeBar(timeLeft, vrOntimeLeft) {
  const displayTimeLeft = timeLeft - ((MAX_TIME_THRESHOLD_MS - MAX_TIME_THRESHOLD_MS_FOR_DISPLAY) / 1000);
  const percentLeft = displayTimeLeft / (MAX_TIME_THRESHOLD_MS_FOR_DISPLAY / 1000);
  if (vrOntimeLeft) {
    vrOntimeLeft(displayTimeLeft, percentLeft);
  }
}

function startTimer(callbacks) {
  const {
    vrOnprocessing,
    vrOntimeLeft,
  } = callbacks;

  let timeLeft = MAX_TIME_THRESHOLD_MS / 1000;
  downloadTimer = setInterval(() => {
    if (timeLeft <= 0) {
      setTimeBar(0, vrOntimeLeft);
      clearInterval(downloadTimer);

      if (processingLeft !== 0) {
        processing = true;
        if (vrOnprocessing) {
          vrOnprocessing(processing);
        }
      }
    } else {
      setTimeBar(timeLeft, vrOntimeLeft);
    }
    timeLeft -= 1;
  }, 1000);
}

export async function startSessionJS(enableHR, enableBR, enableBP, enableSpo2) {
  brEnabled = enableBR;
  hrEnabled = enableHR;
  bpEnabled = enableBP;
  spo2Enabled = enableSpo2;
  
  await endPreview();

  await startSession(enableHR, enableBR, enableBP, enableSpo2);
  shouldProcessFrame = true;
  previewing = false;
  cacheFrames = true;
}

export const isWebWorkerReady = () => {
  return !resetting && onceReady;
};

export const createVitalsRunnerWebWorker = () => {
  if (!webWorker) {
    webWorker = new window.Worker('/workers-rr/worker.js');
    webWorker.onerror = (err) => {
      killWebWorker();
      console.error('webworker error', err);
    };

    rawrPeer = rawr({ transport: transport(webWorker) });
    ({
      startSession,
      endSession,
      detectVitals,
      previewSession,
      getConfigString,
      getVersionString,
      getRunToken,
      authorizeVitals,
    } = rawrPeer.methods);

    rawrPeer.notifications.onready(async () => {
      if (!onceReady) {
        console.log('really ready');
        onceReady = true;
        Object.assign(algorithmConfig, JSON.parse(await getConfigString()));
      }
    });
  }
};

export const addCallbacksToWebWorker = (callbacks) => {
  const {
    vrOnsigns,
    vrOnprocessing,
    vrOnend,
    vrOntimeLeft,
  } = callbacks;

  if (!webWorker) createVitalsRunnerWebWorker();

  webWorker.onmessage = (event) => {
    let msg;
    try {
      msg = JSON.parse(JSON.stringify(event.data, (key, value) => {
        // eslint-disable-next-line
        if (typeof value === 'bigint') {
          return Number(value);
        }
        return value;
      }));
    } catch (e) {
      console.error('unable to serialize msg', e);
    }
    if (!msg) {
      return;
    }
    switch (msg.type) {
      case "signs":
        if (shouldProcessFrame && cacheFrames) {
          responseTimestamps.push(Date.now());
          processedFrameCount++;

          lastSigns = msg.data.signs;
          needSegmentedImage = lastSigns.needSegmentedImage;

          if (!previewing) {
            if (brEnabled) {
              const { brSignalPercent } = lastSigns;
              vrOntimeLeft(null, 1 - brSignalPercent);
            } else {
              const { hrSignalPercent } = lastSigns;
              vrOntimeLeft(null, 1 - hrSignalPercent);
            }
          }

          let trackingInfo = msg.data.trackingInfo;

          if (!tracking && (lastSigns.trackingHR === 1 || lastSigns.trackingBR === 1)) {
            console.log("tracking started");
            tracking = true;
          }

          if (tracking && (lastSigns.trackingHR === 0 || lastSigns.trackingBR === 0)) { 
            console.log("tracking lost");
            reset();
          }

          if (trackingInfo) {
            lastTrackingInfo = trackingInfo;
          }

          if (!previewing && enabledVitalsCollected()) {
            cacheFrames = false;
            endSessionJS({ vrOnend, vrOnprocessing });
          }

          msg.data.cachedFrameCount = cachedFrameCount;
          msg.data.processedFrameCount = processedFrameCount;

          if (vrOnsigns) {
            vrOnsigns(msg.data);
          }
        }

        break; 
      case "redChannel":
        console.log(msg.data);
        break;
      default:
        // console.log('invalid message', msg);
    }
  };
};

export default async function vitalsRunner(video, canvas, patientCanvasVisible, callbacks) {
  const {
    vrGetCoreWarnings,
    vrOnVitalCoreVersion,
  } = callbacks;

  const {
    displayCropH,
    dipslayW,
    cropW,
    displayCropY,
    displayCropX,
    lineWidth,
    edgeSize,
  } = getDimensions(video);

  shouldProcessFrame = true;
  let processFaceDetection = true;
  patientVideo = video;

  let frameCallbackSupported = false;
  if ('requestVideoFrameCallback' in HTMLVideoElement.prototype) {
    frameCallbackSupported = true;
  }

  await initialSessionPreview(callbacks);

  if (patientVideo && videoFrameCallback) {
    patientVideo.cancelVideoFrameCallback(videoFrameCallback);
  } else {
    clearTimeout(onframeTimer);
  }

  async function detectFaceLoop() {
    if (!detector) {
      setTimeout(() => detectFaceLoop(), 100);
      return 0;
    }

    if (!processFaceDetection) {
      return 0;
    }
  
    const estimationConfig = {flipHorizontal: false};
    try {
      if (faceDetectionCounter++ >= EXTERNAL_SKIP_FRAME) {
        const faces = await detector.estimateFaces(video, estimationConfig);
        if (faces && faces.length) {
          let box = faces[0].box;
          // draw or use these 4 box corners for the face
          let widthAdjustment = bboxWidthAdjustment / 100;
          let heightAdjustment = bboxHeightAdjustment / 100;
      
          let cX = (box.xMax + box.xMin) / 2;
          fax = box.xMin - (cX - box.xMin) * widthAdjustment;
          fay = box.yMin - box.height * heightAdjustment;
          faw = box.width * (1 + widthAdjustment);
          fah = box.height * (1 + heightAdjustment);
          // console.log({fax, faw, fay, fah});
          faceDetectionCounter = 0
        } else {
          fax = 0
          fay = 0
          fah = 0
          faw = 0
        }
      }
    } catch (e) {
      console.warn('error processing face detection', e);
    }
    return 0;
    // setTimeout(() => detectFaceLoop());
  }

  const ctx = patientCanvasVisible.getContext('2d');
  const offscreenCtx = canvas.getContext('2d');

  // If restarting frame collection, the existing onframeTimer needs to be cleared
  // so that multiple loops executing onframe (or videoFrameCallback) are not running concurrently
  if (patientVideo && videoFrameCallback) {
    patientVideo.cancelVideoFrameCallback(videoFrameCallback);
  } else {
    clearTimeout(onframeTimer);
  }
  // detectFaceLoop();
  let canvasSized = false;

  const onframe = async () => {
    let timestamp = Date.now();

    if (video.videoWidth > 0) {
      await detectFaceLoop();
      if (!canvasSized) {
        canvas.height = video.videoHeight;
        canvas.width = video.videoWidth;
        canvasSized = true;
      }
      offscreenCtx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

      if (cacheFrames && shouldProcessFrame) {
        front_timestamps.push(timestamp);
        ++frameCount;
        if (!startTimestamp) {
          startTimestamp = timestamp;
          console.log('Starting timestamp:', startTimestamp);
        }

        // calculate frame rate
        let aveFrameInterval = 0;
        if (front_timestamps.length > 0) {
          for (let i = 1; i < front_timestamps.length; ++i) {
            aveFrameInterval += front_timestamps[i] - front_timestamps[i - 1];
          }
          aveFrameInterval += timestamp - front_timestamps[front_timestamps.length - 1];
          aveFrameInterval /= front_timestamps.length;

          averageFrameRate = (1.0 / aveFrameInterval * 1000).toFixed(1);
        }

        const imgBitmap = await createImageBitmap(offscreenCtx.canvas, 0, 0, video.videoWidth, video.videoHeight);

        detectVitalsJS(imgBitmap, video.videoWidth, video.videoHeight, timestamp, needSegmentedImage);
        needSegmentedImage = false;

        processingLeft = cachedFrameCount - processedFrameCount;
      }

      if (cropW > 0) {
        const strokeColor = guideBoxColor;
        ctx.drawImage(video, 0, 0, dipslayW, displayCropH);
        ctx.strokeStyle = strokeColor;
        ctx.lineWidth = lineWidth;
        if (!processing) {
          drawGuideOutline(ctx, video.videoWidth, video.videoHeight);
        }
        if (devMode) {
          ctx.setLineDash([15, 15]);
          ctx.setLineDash([]);
          if (lastSigns && (lastSigns.snrBR || lastSigns.snrHR)) {
            const {
              snrBR,
              lightingScore,
              motionScore,
              qualityScore,
              dataStatus = '',
            } = lastSigns;

            if (lastTrackingInfo) {
              lastTrackingInfo.forEach((t) => {
                if (t.boxes) {
                  t.boxes.forEach((b) => {
                    const tx = Math.round(b.xmin);
                    const tw = Math.round(b.xmax - b.xmin);
                    const ty = Math.round(b.ymin);
                    const th = Math.round(b.ymax - ty);
                    if (t.type === 'FACE') {
                      ctx.strokeStyle = 'blue';
                    } else if (t.type === 'ROI') {
                      ctx.strokeStyle = 'red';
                    }
                    ctx.strokeRect(tx, ty, tw, th);
                  });
                }
              });
            }

            ctx.save();
            ctx.translate(dipslayW, 0);
            ctx.scale(-1, 1);

            const snrStr = `KDE Max: ${snrBR ? Number(snrBR).toFixed(2) : ''}`;
            const motionStr = `light: ${lightingScore} motion: ${motionScore} quality: ${qualityScore}`;
            const dataStatusStr = `status: ${dataStatus}`;
            let activeCoreWarningsStr = 'warning codes: ';
            const activeCoreWarnings = vrGetCoreWarnings();
            if (activeCoreWarnings.length) {
              const warningCodeStr = activeCoreWarnings.map(warning => warning.code).join(',');
              activeCoreWarningsStr += warningCodeStr;
            }
            let closeness = 'closeness: ';
            if (activeCoreWarnings.length && dataStatus.includes('W-RES-079')) {
              closeness += 'Too far';
            } else if (activeCoreWarnings.length && dataStatus.includes('W-RES-080')) {
              closeness += 'Too close';
            } else {
              closeness += 'In range';
            }
            const cachedFrames = `cached frames: ${cachedFrameCount}`;
            const processedFrames = `processed frames: ${processedFrameCount}`;
            ctx.fillStyle = 'black';
            ctx.strokeStyle = 'white';
            ctx.font = `${Math.round(edgeSize * 0.22)}px serif`;
            ctx.lineWidth = 3;

            ctx.strokeText(snrStr, displayCropX + (edgeSize * 0.7) , (displayCropY + (edgeSize * 0.7)) - 5);
            ctx.fillText(snrStr, displayCropX + (edgeSize * 0.7) , (displayCropY + (edgeSize * 0.7)) - 5);
            ctx.strokeText(motionStr, displayCropX + (edgeSize * 0.7) , (displayCropY + (edgeSize * 0.7)) + 13);
            ctx.fillText(motionStr, displayCropX + (edgeSize * 0.7) , (displayCropY + (edgeSize * 0.7)) + 13);
            ctx.strokeText(dataStatusStr, displayCropX + (edgeSize * 0.7) , (displayCropY + (edgeSize * 0.7)) + 31);
            ctx.fillText(dataStatusStr, displayCropX + (edgeSize * 0.7) , (displayCropY + (edgeSize * 0.7)) + 31);
            ctx.strokeText(activeCoreWarningsStr, displayCropX + (edgeSize * 0.7) , (displayCropY + (edgeSize * 0.7)) + 49);
            ctx.fillText(activeCoreWarningsStr, displayCropX + (edgeSize * 0.7) , (displayCropY + (edgeSize * 0.7)) + 49);
            ctx.strokeText(closeness, displayCropX + (edgeSize * 0.7) , (displayCropY + (edgeSize * 0.7)) + 67);
            ctx.fillText(closeness, displayCropX + (edgeSize * 0.7) , (displayCropY + (edgeSize * 0.7)) + 67);
            ctx.strokeText(cachedFrames, displayCropX + (edgeSize * 0.7) , (displayCropY + (edgeSize * 0.7)) + 85);
            ctx.fillText(cachedFrames, displayCropX + (edgeSize * 0.7) , (displayCropY + (edgeSize * 0.7)) + 85);
            ctx.strokeText(processedFrames, displayCropX + (edgeSize * 0.7) , (displayCropY + (edgeSize * 0.7)) + 103);
            ctx.fillText(processedFrames, displayCropX + (edgeSize * 0.7) , (displayCropY + (edgeSize * 0.7)) + 103);
            ctx.restore();
          }
        }
      }
    }
    if (frameCallbackSupported) {
      videoFrameCallback = video.requestVideoFrameCallback(onframe);
    } else {
      onframeTimer = setTimeout(onframe, TARGET_FRAME_INTERVAL_MS);
    }
  };

  if (preparationVideoElement && frameCallbackSupported) {
    preparationVideoElement.cancelVideoFrameCallback(preparationVideoVFC);
  } else {
    cancelAnimationFrame(preparationVideoRAF);
  }

  if (preventSessionOnUnmount) {
    // this addresses a bug where startSession ends up being called if there is an error
    // causing a reset, and a user clicks the back button during the reset which should
    // end the session
    // NOTE - automatic restarts are currently not being implemented
    preventSessionOnUnmount = false;
    return;
  }

  if (frameCallbackSupported) {
    videoFrameCallback = video.requestVideoFrameCallback(onframe);
  } else {
    onframeTimer = setTimeout(onframe, TARGET_FRAME_INTERVAL_MS);
  }

  const ivcVersion = await getVersionString();
  vrOnVitalCoreVersion(ivcVersion);
}

export async function restartSession(video, canvas, patientCanvasVisible, callbacks) {
  stopTimer();
  cacheFrames = false;
  shouldProcessFrame = false;
  resetting = true;
  await endSession();
  reset();
  vitalsRunner(video, canvas, patientCanvasVisible, callbacks);
}

// download timer is getting started
export async function resetVitalsRunnerOnUnmount() {
  preventSessionOnUnmount = true;
  stopTimer();
  if (patientVideo && videoFrameCallback) {
    patientVideo.cancelVideoFrameCallback(videoFrameCallback);
  } else {
    clearTimeout(onframeTimer);
  }
  shouldProcessFrame = false;
  resetting = true;
  await endSession();
  window.cancelAnimationFrame(onframeRAF);
  reset();
}

export const preparationVideo = async (video, patientCanvasVisible) => {
  const {
    displayCropH,
    dipslayW,
    lineWidth,
  } = getDimensions(video);

  return new Promise((resolve) => {
    const ctx = patientCanvasVisible.getContext('2d');

    let frameCallbackSupported = false;
    if ('requestVideoFrameCallback' in HTMLVideoElement.prototype) {
      preparationVideoElement = video;
      frameCallbackSupported = true;
    }

    const onframe = async () => {
      if (video.videoWidth > 0) {
        if (dipslayW > 0) {
          const strokeColor = guideBoxColor;

          ctx.drawImage(video, 0, 0, dipslayW, displayCropH);

          ctx.strokeStyle = strokeColor;
          ctx.lineWidth = lineWidth;

          drawGuideOutline(ctx, dipslayW, displayCropH);
        }
      }
  
      if (frameCallbackSupported) {
        preparationVideoVFC = video.requestVideoFrameCallback(onframe);
      } else {
        preparationVideoRAF = requestAnimationFrame(onframe);
      }
    };

    // wait a bit because first few frames are always black when turning on camera.
    setTimeout(() => {
      if (frameCallbackSupported) {
        preparationVideoVFC = video.requestVideoFrameCallback(onframe);
      } else {
        preparationVideoRAF = requestAnimationFrame(onframe);
      }

      resolve();
    }, 200);
  });
};

const drawGuideOutline = (ctx, width, height) => {
  ctx.beginPath();

  if (width > height) {
    ctx.ellipse(320, 212.5, 110, 137.5, 0, 0, 2 * Math.PI);
  } else {
    ctx.ellipse(240, 252.5, 130, 167.5, 0, 0, 2 * Math.PI);
  }
  
  ctx.stroke();
};

export const stopPreparationVideo = () => {
  let frameCallbackSupported = false;
  if ('requestVideoFrameCallback' in HTMLVideoElement.prototype) {
    frameCallbackSupported = true;
  }

  if (preparationVideoElement && frameCallbackSupported) {
    preparationVideoElement.cancelVideoFrameCallback(preparationVideoVFC);
  } else {
    cancelAnimationFrame(preparationVideoRAF);
  }
};
