import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { filter } from 'lodash';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';

import { Smoking } from '../lib/icons';
import ProWrapper from '../lib/pro-wrapper';

import Page from './page';

import { updatePRO } from '../state/pro-forms';

const styles = {
  button: {
    margin: 5,
    width: 150,
    height: 40,
    fontSize: 16,
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  checkboxLabel: {
    fontSize: '22px',
  },
  formControl: {
    textAlign: 'center',
  },
  formControlItem: {
    fontSize: '22px',
    textAlign: 'center',
    margin: '1rem',
  },
  instructions: {
    fontSize: '1.3rem',
  },
  titleGroup: {
    margin: 25,
  },
  text: {
    fontSize: '22px',
  },
};

const LargeCheckbox = withStyles(() => ({
  root: {
    transform: 'scale(1.5)',
  },
}))(Checkbox);


class Diabetes9 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      smoker: null,
      typeOfSmoker: [],
    };

    this.handleContinue = this.handleContinue.bind(this);
  }

  handleContinue() {
    this.props.updatePRO({
      type: 'diabetesInitial',
      position: this.getTrackIndex(),
      value: this.state });
    this.forwardWithQuery(this.props.location.query);
  }

  handleCheckbox = (e) => {
    const { name, checked } = e.target;
    const { typeOfSmoker } = this.state;
    if (!checked && typeOfSmoker.includes(name)) {
      this.setState({ typeOfSmoker: filter(typeOfSmoker, (type) => { return type !== name; }) });
    } else if (checked && !typeOfSmoker.includes(name)) {
      this.setState({ typeOfSmoker: [...typeOfSmoker, name] });
    }
  };

  render() {
    const { smoker, typeOfSmoker } = this.state;

    let disabled;

    if (smoker === true && typeOfSmoker.length) {
      disabled = false;
    } else if (smoker === false) {
      disabled = false;
    } else {
      disabled = true;
    }

    return (
      <ProWrapper
        header="Smoking"
        currentPage={this.getCurrentPage()}
        totalPages={this.getTotalPages()}
        onContinue={this.handleContinue}
        disabled={disabled}
        icon={<Smoking />}
      >
        <div style={styles.titleGroup}>
          <p style={styles.text}>Do you smoke?</p>
          <div style={styles.buttonGroup}>
            <Button style={styles.button} variant="contained" color={smoker ? 'primary' : 'default'} onClick={() => this.setState({ smoker: true })}>Yes</Button>
            <Button style={styles.button} variant="contained" color={smoker === false ? 'primary' : 'default'} onClick={() => this.setState({ smoker: false, typeOfSmoker: [] })}>No</Button>
          </div>
        </div>

        <div className={`${smoker ? '' : 'hidden'}`} style={styles.titleGroup}>
          <p style={styles.text}>What do you smoke?</p>
          <FormControl component="fieldset" style={styles.formControlItem}>
            <FormGroup>
              <FormControlLabel
                control={<LargeCheckbox checked={typeOfSmoker.includes('Cigarettes')} onChange={this.handleCheckbox} name="Cigarettes" />}
                E-Cigarettes
                label={(
                  <div style={styles.checkboxLabel}>
                    <Typography variant="title">
                      Cigarettes
                    </Typography>
                  </div>
                )}
              />
              <FormControlLabel
                control={<LargeCheckbox checked={typeOfSmoker.includes('e-Cigarettes')} onChange={this.handleCheckbox} name="e-Cigarettes" />}
                label={(
                  <div style={styles.checkboxLabel}>
                    <Typography variant="title">
                      E-Cigarettes
                    </Typography>
                  </div>
                )}
              />
              <FormControlLabel
                control={<LargeCheckbox checked={typeOfSmoker.includes('Cigars')} onChange={this.handleCheckbox} name="Cigars" />}
                label={(
                  <div style={styles.checkboxLabel}>
                    <Typography variant="title">
                      Cigars
                    </Typography>
                  </div>
                )}
              />
            </FormGroup>
          </FormControl>
        </div>
      </ProWrapper>
    );
  }
}

function mapStateToProps(state) {
  const { user, proForms: { diabetesInitial } } = state;
  return { user, diabetesInitial };
}

Diabetes9.proptypes = {
  user: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(Diabetes9);
