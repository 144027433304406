import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';

import { Walking2 } from '../lib/icons';
import ProWrapper from '../lib/pro-wrapper';

import Page from './page';

import { updatePRO } from '../state/pro-forms';

const styles = {
  button: {
    margin: 5,
    width: 150,
    height: 40,
    fontSize: 16,
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formControl: {
    textAlign: 'center',
  },
  formControlItem: {
    fontSize: '1.75rem',
    textAlign: 'center',
    margin: '1rem',
  },
  instructions: {
    fontSize: '1.3rem',
  },
  titleGroup: {
    margin: 25,
  },
  text: {
    fontSize: '22px',
  },
  textField: {
    width: '100%',
  },
};

class DiabetesDaily4 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      exercisedToday: null,
    };

    this.handleContinue = this.handleContinue.bind(this);
  }

  handleContinue() {
    this.props.updatePRO({
      type: 'diabetesDaily',
      position: this.getTrackIndex(),
      value: this.state });
    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const { exercisedToday } = this.state;

    return (
      <ProWrapper
        header="Exercise"
        currentPage={this.getCurrentPage()}
        totalPages={this.getTotalPages()}
        onContinue={this.handleContinue}
        disabled={exercisedToday === null}
        icon={<Walking2 />}
      >
        <div style={styles.titleGroup}>
          <p style={styles.text}>Did you exercise at least 30 minutes today?</p>
          <div style={styles.buttonGroup}>
            <Button style={styles.button} variant="contained" color={exercisedToday ? 'primary' : 'default'} onClick={() => this.setState({ exercisedToday: true})}>Yes</Button>
            <Button style={styles.button} variant="contained" color={exercisedToday === false ? 'primary' : 'default'} onClick={() => this.setState({ exercisedToday: false })}>No</Button>
          </div>
        </div>
      </ProWrapper>
    );
  }
}

function mapStateToProps(state) {
  const { user, proForms: { diabetesDaily } } = state;
  return { user, diabetesDaily };
}

DiabetesDaily4.proptypes = {
  user: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(DiabetesDaily4);
