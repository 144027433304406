import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { get, debounce } from 'lodash';
import { apiFetch, makeShadowOptions } from '../lib/fetch';
import AppBar from '../components/app-bar';
import Continue from '../components/continue';
import ClinicLogo from '../components/clinic-logo';
import Page from './page';
import i18nTranslator, { title, subtitle, instructions, options } from '../lib/i18next';


const styles = {
  agreement: {
    fontSize: '1.25rem',
    margin: '10px 20px',
  },
  btnContainer: {
    textAlign: 'center',
    width: '100%',
  },
  continueBtn: {
    maxWidth: '300px',
  },
};

class VideoCallPro1 extends Page {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      clinicId: null,
    };
  }

  handleAgree = debounce(async () => {
    this.setState({ submitting: true });
    const { clinicId, user, userId } = this.props;

    const consentPayload = {
      clinic_id: clinicId,
      consent_type: 'TELEHEALTH',
    };

    const appointmentId = this.props.router.location.state.appointment_id;

    const options = makeShadowOptions(consentPayload, user.token, 'POST');
    try {
      const sound = new Audio();
      sound.play();
      await apiFetch(`/users/${userId}/consent`, options);
      apiFetch(`/users/${userId}/appointments/${appointmentId}/add_user_agent`, {
        method: 'PUT',
      });
      this.setState({ submitting: false }, () => {
        this.forwardWithQuery();
      });
    } catch (e) {
      this.setState({ submitting: false });
    }
  }, 500)

  render() {
    const { classes, clinicId } = this.props;

    return (
      <div>
        <AppBar
          noLeftNode={true}
          headerNode={title('videoCallPro1')}
        />
        <ClinicLogo clinic_id={clinicId} />
        {this.state.submitting ? (
          <CircularProgress />
        ) : (
          <div className={classes.agreement}>
            <p>{subtitle('videoCallPro1')}</p>
            <div className={classes.btnContainer}>
              <Continue
                btnStyle={styles.continueBtn}
                text={i18nTranslator('agree', 'videoCallPro1')}
                onClick={this.handleAgree}
              />
            </div>
            <h2>{i18nTranslator('title2', 'videoCallPro1')}</h2>
            {instructions('videoCallPro1')}
            <ul>
              <li>{options('option1', 'videoCallPro1')}</li>
              <li>{options('option2', 'videoCallPro1')}</li>
              <li>{options('option3', 'videoCallPro1')}</li>
              <li>{options('option4', 'videoCallPro1')}</li>
              <li>{options('option5', 'videoCallPro1')}</li>
              <li>{options('option6', 'videoCallPro1')}</li>
              <li>{options('option7', 'videoCallPro1')}</li>
              <li>{options('option8', 'videoCallPro1')}</li>
              <li>{options('option9', 'videoCallPro1')}</li>
              <li>{options('option10', 'videoCallPro1')}</li>
              <li>{options('option11', 'videoCallPro1')}</li>
              <li>{options('option12', 'videoCallPro1')}</li>
              <li>{options('option13', 'videoCallPro1')}</li>
            </ul>
            <div className={classes.btnContainer}>
              <Continue
                disabled={this.state.submitting}
                btnStyle={styles.continueBtn}
                onClick={this.handleAgree}
                text={i18nTranslator('agree', 'videoCallPro1')}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { user } = state;
  const { clinic_id } = ownProps.location.query;

  return {
    clinicId: get(user, 'meta.clinic_id', clinic_id),
    userId: get(user, 'id'),
    user,
  };
}

export default connect(mapStateToProps, {})(withStyles(styles)(VideoCallPro1));
