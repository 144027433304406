import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Layout from '../layouts/common';

import Continue from '../components/continue';

import { colors } from '../lib/styles';

import Page from './page';

import { submitPRO } from '../state/pro-forms';

const styles = {
  btn: {
    textAlign: 'center',
    width: '100%',
    maxWidth: '27rem',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0px 10px',
  },
  greenCheckMark: {
    marginTop: '10rem',
    marginBottom: '8rem',
    width: '8rem',
    height: '8rem',
  },
  paragraph: {
    color: colors.primaryColor,
    fontSize: '2rem',
    marginBottom: '10rem',
  },
};

class DiabetesDaily7 extends Page {
  state = {
    submitting: false,
  }

  handleContinue = async () => {
    const { id } = this.props.location.query;
    const { user, diabetesDaily } = this.props;
    const { submitting } = this.state;
    if (submitting) return;
    this.setState({ submitting: true });
    try {
      const data = [...diabetesDaily];
      await this.props.submitPRO({ userId: user.id, id }, {
        pro_type: 'DIABETES-DAILY',
        pro_data: { data },
      }, user);
      this.forwardWithQuery();
    } catch (e) {
      this.setState({ submitting: false, error: e });
      console.error(e);
    }
  }

  render() {
    if (!this.props.diabetesDaily[5]) return null;

    const { currentBloodSugar } = this.props.diabetesDaily[5];

    let page;

    if (Number(currentBloodSugar) < 70) {
      page = (
        <Layout>
          <div style={styles.container}>
            <img
              src="/img/GreenCheckmarkCircle.png"
              style={styles.greenCheckMark}
              alt="Green check mark"
            />
            <p style={styles.paragraph}>Your blood sugar is less than 70.</p>
            <p style={styles.paragraph}>Please eat or take a glucose tablet and recheck your blood sugar in 15 minutes.</p>
            <Continue
              onClick={this.handleContinue}
              btnStyle={styles.btn}
            />
          </div>
        </Layout>
      );
    } else if (Number(currentBloodSugar) > 180) {
      page = (
        <Layout>
          <div style={styles.container}>
            <img
              src="/img/GreenCheckmarkCircle.png"
              style={styles.greenCheckMark}
              alt="Green check mark"
            />
            <p style={styles.paragraph}>Your blood sugar is high.</p>
            <p style={styles.paragraph}>Please take your insulin or reach out to your physician.</p>
            <Continue
              onClick={this.handleContinue}
              btnStyle={styles.btn}
            />
          </div>
        </Layout>
      );
    } else {
      this.handleContinue();
    }

    return (
      <Fragment>
        {page}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user, proForms: { diabetesDaily } } = state;
  return { user, diabetesDaily };
}

DiabetesDaily7.proptypes = {
  user: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { submitPRO })(DiabetesDaily7);
