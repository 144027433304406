import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Page from './page';
import AppBar from '../components/app-bar';
import Continue from '../components/continue';
import ClinicLogo from '../components/clinic-logo';

import { title, instructions, nextBtn } from '../lib/i18next';

const styles = {
  continueBtn: {
    textAlign: 'center',
    alignSelf: 'center',
    margin: '10px',
  },
  continueBtnWrapper: {
    textAlign: 'center',
    width: '100%',
    bottom: 0,
    position: 'absolute',
    height: '8rem',
  },
  titleGroup: {
    margin: 25,
    textAlign: 'center',
    height: '100%',
  },
  text: {
    fontSize: '22px',
  },
  mainContainer: {
    position: 'relative',
    minHeight: '100vh',
    marginBottom: '50px',
  },
  wrapper: {
    paddingBottom: '8rem',
    height: '100%',
  },
};

class VideoDisclaimer extends Page {
  render() {
    const { clinic_id } = this.props;
    return (
      <div style={styles.mainContainer}>
        <AppBar
          headerNode={title('videoBroadcast1')}
          noLeftNode={true}
        />
        <ClinicLogo clinic_id={clinic_id} />
        <div style={styles.wrapper}>
          <div style={styles.titleGroup}>
            <p style={styles.text}>{instructions('videoBroadcast1')}</p>
          </div>
        </div>
        <div style={styles.continueBtnWrapper}>
          <Continue
            text={nextBtn()}
            onClick={() => {
              this.forward();
            }}
            btnStyle={styles.continueBtn}
          />
        </div>
      </div>
    );
  }
}


function mapStateToProps(state) {
  
  const { requesting_clinic_id } = state.user;
  return {
    clinic_id: requesting_clinic_id,
  };
}

VideoDisclaimer.proptypes = {
  route: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, {})(VideoDisclaimer);
