import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';

import { WineGlass } from '../lib/icons';
import ProWrapper from '../lib/pro-wrapper';

import Page from './page';

import { updatePRO } from '../state/pro-forms';

const styles = {
  button: {
    margin: 5,
    width: 150,
    height: 40,
    fontSize: 16,
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formControl: {
    textAlign: 'center',
  },
  formControlItem: {
    fontSize: '22px',
    textAlign: 'center',
    margin: '1rem',
  },
  titleGroup: {
    margin: 25,
  },
  text: {
    fontSize: '22px',
  },
};

const StyledInput = withStyles(() => ({
  input: {
    textAlign: 'center',
    fontSize: '22px',
  },
}))(Input);

class Diabetes10 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      drinker: null,
      drinksPerWeek: null,
    };

    this.handleContinue = this.handleContinue.bind(this);
  }

  handleContinue() {
    this.props.updatePRO({
      type: 'diabetesInitial',
      position: this.getTrackIndex(),
      value: this.state });
    this.forwardWithQuery(this.props.location.query);
  }

  handleDrinkCount = (e) => {
    const { value } = e.target;
    this.setState({
      drinksPerWeek: value,
    });
  };

  render() {
    const { drinker, drinksPerWeek } = this.state;

    let disabled;

    if (drinker === true && drinksPerWeek > 0) {
      disabled = false;
    } else if (drinker === false) {
      disabled = false;
    } else {
      disabled = true;
    }

    return (
      <ProWrapper
        header="Fluid Intake"
        currentPage={this.getCurrentPage()}
        totalPages={this.getTotalPages()}
        onContinue={this.handleContinue}
        disabled={disabled}
        icon={<WineGlass />}
      >
        <div style={styles.titleGroup}>
          <p style={styles.text}>Do you drink alcohol?</p>
          <div style={styles.buttonGroup}>
            <Button style={styles.button} variant="contained" color={drinker ? 'primary' : 'default'} onClick={() => this.setState({ drinker: true })}>Yes</Button>
            <Button style={styles.button} variant="contained" color={drinker === false ? 'primary' : 'default'} onClick={() => this.setState({ drinker: false, drinksPerWeek: '' })}>No</Button>
          </div>
        </div>

        <div className={`${drinker ? '' : 'hidden'}`} style={styles.titleGroup}>
          <p style={styles.text}>How many drinks per week?</p>
          <div style={styles.formControl}>
            <FormControl style={styles.formControlItem}>
              <StyledInput
                id="drinksPerWeek"
                type="tel"
                value={drinksPerWeek}
                name="drinksPerWeek"
                placeholder="00"
                onChange={this.handleDrinkCount}
                inputProps={{
                  'aria-label': 'drinksPerWeek',
                }}
              />
            </FormControl>
            <Typography style={styles.text}>drinks</Typography>
          </div>
        </div>
      </ProWrapper>
    );
  }
}

function mapStateToProps(state) {
  const { user, proForms: { diabetesInitial } } = state;
  return { user, diabetesInitial };
}

Diabetes10.proptypes = {
  user: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(Diabetes10);
