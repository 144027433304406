import React, { Component } from 'react';
import PropTypes from 'prop-types';

import brand from '../lib/brand';
import { apiFetch } from '../lib/fetch';

const styles = {
  logoContainer: {
    padding: '15px 60px',
    textAlign: 'center',
    margin: 'auto',
  },
  logo: {
    maxHeight: '150px',
  },
};

class ClinicLogo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clinic_logo: '',
    };
  }

  async componentWillMount() {
    const logo = await apiFetch(`/clinics/${this.props.clinic_id}/logo/400`);
    this.setState({
      clinic_logo: logo.url,
    });
  }

  render() {
    const { clinic_id } = this.props;
    return (
      <div style={styles.logoContainer}>
        <img
          alt="Clinic Logo"
          style={styles.logo}
          src={this.state.clinic_logo}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = clinic_id === null ? '' : `/img/${brand.name}/${brand.loginLogo}`;
          }}
        />
      </div>
    );
  }
}

ClinicLogo.propTypes = {
  clinic_id: PropTypes.number.isRequired,
};

export default ClinicLogo;
