import React from 'react';
import PropTypes from 'prop-types';

import { LinearProgress } from '@material-ui/core';

import AppBar from '../components/app-bar';
import Continue from '../components/continue';

const styles = {
  mainContainer: {
    position: 'relative',
    minHeight: '100vh',
    marginBottom: '50px',
  },
  iconContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    flexGrow: 0,
    flexShrink: 0,
    width: '100%',
    color: '#D73B3B',
    paddingTop: 10,
  },
  iconWrapper: {
    width: 100,
    marginTop: 10,
  },
  nextBtn: {
    textAlign: 'center',
    width: '100%',
    bottom: 0,
    position: 'absolute',
    height: '8rem',
    marginTop: '8rem',
  },
  wrapper: {
    paddingBottom: '8rem',
  },
};

const ProWrapper = ({ children, currentPage, totalPages, onContinue, disabled=false, header, noLeftNode = true, icon, iconColor="#000", nextBtn="Next" }) => {
  return (
    <div style={styles.mainContainer}>
      <AppBar
        headerNode={header}
        noLeftNode={noLeftNode}
      />
      <LinearProgress
        variant="determinate"
        value={(currentPage / totalPages) * 100}
      />
      <div style={{...styles.iconContainer, color: iconColor}}>
        <div style={styles.iconWrapper}>
          {icon}
        </div>
      </div>
      <div style={styles.wrapper}>
        {children}
      </div>
      <div style={styles.nextBtn}>
        <Continue
          disabled={disabled}
          text={nextBtn}
          onClick={onContinue}
        />
      </div>
    </div>
  );
};

ProWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onContinue: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default ProWrapper;