import React from 'react';
import { Button, LinearProgress, MenuItem, Select, TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import Page from './page';
import QuestionLayout from '../layouts/question';
import i18nTranslator, { title, options, nextBtn, selectDefault } from '../lib/i18next';
import { colors } from '../lib/styles';
import { submitPRO, updatePRO } from '../state/pro-forms';
import { Syringe } from '../lib/icons';

const styles = {
  button: {
    width: 200,
    borderRadius: 5,
    marginLeft: 10,
    paddingBottom: 15,
    paddingTop: 15,
    fontSize: 22,
  },
  buttonContainer: {
    display: 'flex',
    marginLeft: -10,
    marginTop: 8,
  },
  contentWrapper: {
    flexGrow: 1,
    overflowX: 'hidden',
    position: 'relative',
  },
  imgRow: {
    display: 'flex',
    justifyContent: 'center',
    padding: '15px 60px',
  },
  imageWrapper: {
    width: 100,
  },
  questionnaireContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  questionText: {
    fontSize: 29,
    marginTop: 25,
  },
  select: {
    fontSize: 29,
    marginTop: 5,
  },
  textField: {
    marginTop: 20,
  },
};

class PisaInjection1 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      injectionLocation: '',
      injectionLocationOther: '',
    };
  }

  handleChangeInjectionLocation = (e) => {
    this.setState({ injectionLocation: e.target.value });
  };

  handleChangeInjectionLocationOther = (e) => {
    this.setState({ injectionLocationOther: e.target.value });
  }

  handleClickNext = () => {
    const {
      injectionLocation,
      injectionLocationOther,
    } = this.state;

    const value = {
      injectionLocation: injectionLocation === 'Other' ? injectionLocationOther : injectionLocation,
    };

    this.props.updatePRO({
      type: 'pisaInjection',
      position: this.getTrackIndex(),
      value,
    });

    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const { classes, user: { primary_language } } = this.props;
    const {
      injectionLocation,
      injectionLocationOther,
    } = this.state;

    let continueDisabled = true;

    if (injectionLocation && injectionLocation !== 'Other') {
      continueDisabled = false;
    } else if (injectionLocation === 'Other' && injectionLocationOther) {
      continueDisabled = false;
    }

    const placeholder = primary_language === 'en' ? 'Tap to enter' : 'Toca aquí para entrar';

    return (
      <QuestionLayout
        primaryBtnLabel={nextBtn()}
        primaryBtnOnTouchTap={this.handleClickNext}
        primaryBtnDisabled={continueDisabled}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode={title('pisaInjection1')}
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          classes={{ bar: classes.bar }}
        />
        <div className={classes.contentWrapper}>
          <div className={classes.imgRow}>
            <div className={classes.imageWrapper}>
              <Syringe />
            </div>
          </div>
          <div className={classes.questionnaireContainer}>
            <div className={classes.questionText}>{i18nTranslator('question1', 'pisaInjection1')}</div>
            <div className={classes.questionText}>{i18nTranslator('question2', 'pisaInjection1')}</div>
            <Select
              displayEmpty
              value={injectionLocation}
              onChange={this.handleChangeInjectionLocation}
              className={classes.select}
            >
              <MenuItem value=""><i>{selectDefault()}</i></MenuItem>
              <MenuItem value="Neck">{options('neck', 'pisaInjection1')}</MenuItem>
              <MenuItem value="Shoulders">{options('shoulders', 'pisaInjection1')}</MenuItem>
              <MenuItem value="Upper back">{options('upperBack', 'pisaInjection1')}</MenuItem>
              <MenuItem value="Mid back">{options('midBack', 'pisaInjection1')}</MenuItem>
              <MenuItem value="Low back">{options('lowBack', 'pisaInjection1')}</MenuItem>
              <MenuItem value="Hip">{options('hip', 'pisaInjection1')}</MenuItem>
              <MenuItem value="Knee">{options('knee', 'pisaInjection1')}</MenuItem>
              <MenuItem value="Other">{options('other', 'pisaInjection1')}</MenuItem>
            </Select>
            {injectionLocation === 'Other' ? (
              <TextField
                fullWidth
                placeholder={placeholder}
                onChange={this.handleChangeInjectionLocationOther}
                className={classes.textField}
                value={injectionLocationOther}
              />
            ) : null}
          </div>
        </div>
      </QuestionLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    proForms: {
      pisaInjection,
    },
    user,
  } = state;

  return { pisaInjection, user };
};

export default connect(mapStateToProps, { submitPRO, updatePRO })(withStyles(styles)(PisaInjection1));
