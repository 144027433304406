import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';

import { KeyboardDatePicker } from '@material-ui/pickers';

import { WaterDrop } from '../lib/icons';
import ProWrapper from '../lib/pro-wrapper';

import Page from './page';

import { updatePRO } from '../state/pro-forms';

const styles = {
  button: {
    margin: 5,
    width: 150,
    height: 40,
    fontSize: 16,
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  datePicker: {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
  },
  formControl: {
    display: 'inline-flex',
    justifyContent: 'center',
    width: '100%',
  },
  formControlItem: {
    fontSize: '22px',
    textAlign: 'center',
    margin: '1rem',
  },
  formControlTypography: {
    alignSelf: 'center',
  },
  instructions: {
    fontSize: '1.3rem',
  },
  titleGroup: {
    margin: 25,
  },
  text: {
    fontSize: '22px',
  },
};

const StyledInput = withStyles(() => ({
  input: {
    textAlign: 'center',
    fontSize: '22px',
  },
}))(Input);

const StyledKeyboardDatePicker = withStyles(() => ({
  root: {
    '& .MuiInputBase-root': {
      fontSize: '16px',
    },
  },
}))(KeyboardDatePicker);

class Diabetes14 extends Page {  
  constructor(props) {
    super(props);
    this.handleDate = this.handleDate.bind(this, 'lastFastingBloodSugarCheck');
    this.handleContinue = this.handleContinue.bind(this);

    this.state = {
      lastFastingBloodSugarCheck: new Date(),
      a1c: null,
    };
  }

  handleContinue() {
    this.props.updatePRO({
      type: 'diabetesInitial',
      position: this.getTrackIndex(),
      value: this.state });
    this.forwardWithQuery(this.props.location.query);
  }

  handleA1C = (e) => {
    const { value } = e.target;
    this.setState({
      a1c: value,
    });
  };

  handleDate = (field, date) => {
    this.setState({
      [field]: date,
    });
  };

  render() {
    const { lastFastingBloodSugarCheck, a1c } = this.state;

    let disabled;

    if (lastFastingBloodSugarCheck !== null && a1c !== null) {
      disabled = false;
    } else {
      disabled = true;
    }

    return (
      <ProWrapper
        header="Blood Sugar"
        currentPage={this.getCurrentPage()}
        totalPages={this.getTotalPages()}
        onContinue={this.handleContinue}
        icon={<WaterDrop />}
        disabled={disabled}
      >
        <div style={styles.titleGroup}>
          <p style={styles.text}>When was your last fasting blood sugar check?</p>
          <div style={styles.formControl}>
            <StyledKeyboardDatePicker
              autoOk={true}
              disableToolbar
              defaultDate={new Date()}
              maxDate={new Date()}
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              id="date-picker"
              value={lastFastingBloodSugarCheck}
              onChange={this.handleDate}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </div>
        </div>
        <div style={styles.titleGroup}>
          <p style={styles.text}>What is your current A1C?</p>
          <div style={styles.formControl}>
            <FormControl style={styles.formControlItem}>
              <StyledInput
                id="a1c"
                type="tel"
                value={a1c}
                name="a1c"
                placeholder="00"
                onChange={this.handleA1C}
                inputProps={{
                  'aria-label': 'a1c',
                }}
              />
            </FormControl>
            <Typography style={styles.formControlTypography}>%</Typography>
          </div>
        </div>
      </ProWrapper>
    );
  }
}

function mapStateToProps(state) {
  const { user, proForms: { diabetesInitial } } = state;
  return { user, diabetesInitial };
}

Diabetes14.proptypes = {
  user: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(Diabetes14);
