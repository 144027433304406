import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

import { colors } from '../lib/styles';

const baseStyles = {
  label: {
    color: colors.white,
    fontSize: 22,
  },
  labelOutlined: {
    color: colors.primaryColor,
    fontSize: 22,
  },
  btn: {
    backgroundColor: colors.primaryColor,
    borderRadius: '0.3125rem',
    minWidth: '17.1875rem',
    color: colors.white,
    marginBottom: '0.625rem',
    marginTop: '1rem',
    width: '85vw',
    minHeight: '4rem',
  },
  btnOutlined: {
    borderRadius: '0.3125rem',
    minWidth: '17.1875rem',
    borderColor: colors.primaryColor,
    marginBottom: '0.625rem',
    marginTop: '0.0625rem',
    width: '85vw',
    minHeight: '4rem',
  }
};

class Continue extends Component {
  render() {
    let btnDisabled = {};

    if (this.props.disabled) {
      btnDisabled = {
        opacity: 0.5,
      };
    }

    let labelStyle;
    let btnStyle;

    if (this.props.variant === 'outlined') {
      labelStyle = Object.assign({}, baseStyles.labelOutlined, this.props.labelStyle);
      btnStyle = Object.assign({}, baseStyles.btnOutlined, this.props.btnStyle);
    } else {
      labelStyle = Object.assign({}, baseStyles.label, this.props.labelStyle);
      btnStyle = Object.assign({}, baseStyles.btn, this.props.btnStyle);
    }

    return (
      <Button
        href={this.props.href}
        target={this.props.target}
        disabled={this.props.disabled}
        fullWidth={true}
        style={{ ...btnStyle, ...btnDisabled, ...labelStyle }}
        onClick={this.props.onClick}
        variant={this.props.variant}
      >{this.props.text}</Button>
    );
  }
}

Continue.defaultProps = {
  href: null,
  target: null,
  btnStyle: {},
  disabled: false,
  labelStyle: {},
  onClick: null,
  text: 'Continue',
  variant: undefined,
};
Continue.propTypes = {
  disabled: PropTypes.bool,
  text: PropTypes.string,
  href: PropTypes.string,
  target: PropTypes.string,
  onClick: PropTypes.func,
  btnStyle: PropTypes.object,
  labelStyle: PropTypes.object,
  variant: PropTypes.string,
};

export default Continue;
