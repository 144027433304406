import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Slider from '@material-ui/core/Slider';

import { Smile, Meh, Frown } from '../lib/icons';
import { painScaleColors } from '../lib/styles';
import ProWrapper from '../lib/pro-wrapper';

import Page from './page';

import { updatePRO } from '../state/pro-forms';

const styles = {
  contentWrapper: {
    flexGrow: 1,
    overflowX: 'hidden',
    position: 'relative',
    justifyContent: 'space-between',
  },
  iconContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    flexGrow: 0,
    flexShrink: 0,
    width: '100%',
    color: '#000',
    paddingTop: 10,
  },
  iconWrapper: {
    width: 100,
    marginTop: 10,
  },
  instructions: {
    fontSize: '1.3rem',
  },
  titleGroup: {
    margin: 25,
    textAlign: 'center',
  },
  text: {
    fontSize: '22px',
  },
  button: {
    margin: '5px',
  },
  nextBtn: {
    textAlign: 'center',
    width: '100%',
    top: '90%',
    position: 'fixed',
  },
  paragraph: {
    textAlign: 'center',
    marginLeft: '15px',
    marginRight: '15px',
    fontSize: '22px',
  },
  slider: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '50px',
    fontSize: '14px',
  },
  sliderStyle: {
    flexGrow: 1,
    marginBottom: 24,
  },
  sliderLabel: {
    fontSize: 14,
    maxWidth: 100,
    textAlign: 'center',
  },
  questionnaire: {
    margin: '0px 30px',
    position: 'relative',
  },
};

class Diabetes12 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      theme: this.getSliderTheme(0),
      stressLevel: 0,
    };

    this.handleContinue = this.handleContinue.bind(this);
  }

  getSliderTheme = (value) => {
    return {
      color: painScaleColors[value],
    };
  }

  handleStressLevelSlider = (e, value) => {
    const theme = this.getSliderTheme(value);
    this.setState({ theme, stressLevel: value });
  }

  handleContinue() {
    this.props.updatePRO({
      type: 'diabetesInitial',
      position: this.getTrackIndex(),
      value: this.state });
    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const { theme, stressLevel } = this.state;

    let face;
    if (stressLevel < 5) {
      face = (
        <div style={styles.iconContainer}>
          <div style={styles.iconWrapper}>
            <Smile />
          </div>
        </div>
      );
    }
    if (stressLevel >= 5 && stressLevel < 8) {
      face = (
        <div style={styles.iconContainer}>
          <div style={styles.iconWrapper}>
            <Meh />
          </div>
        </div>
      );
    }
    if (stressLevel >= 8 && stressLevel < 11) {
      face = (
        <div style={styles.iconContainer}>
          <div style={styles.iconWrapper}>
            <Frown />
          </div>
        </div>
      );
    }

    return (
      <ProWrapper
        header="Stress"
        currentPage={this.getCurrentPage()}
        totalPages={this.getTotalPages()}
        onContinue={this.handleContinue}
        icon={face}
      >
        <div style={styles.contentWrapper}>
          <div style={styles.questionnaire}>
            <p style={styles.paragraph}>How would you rate your current level of stress?</p>
            <Slider
              min={0}
              max={10}
              value={0 || stressLevel}
              valueLabelDisplay="on"
              onChange={this.handleStressLevelSlider}
              style={{ ...styles.slider, ...theme }}
            />
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <div style={{ ...styles.sliderLabel, marginBottom: '50px' }}>
              No stress
              </div>
              <div style={{ ...styles.sliderLabel, marginBottom: '50px' }}>
              Worst imaginable stress
              </div>
            </div>
          </div>
        </div>
      </ProWrapper>
    );
  }
}

function mapStateToProps(state) {
  const { user, proForms: { diabetesInitial } } = state;
  return { user, diabetesInitial };
}

Diabetes12.proptypes = {
  user: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(Diabetes12);
